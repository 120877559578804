const ARBITRARY_HIGH_NUMBER = 10000;
import api from '@/lib/api';

const state = {
    // The cart is basically a collection of visit records
    cart: [],
    cartStatus: 'loading',
    isAddingVisit: false,
};

const getters = {
    isCartLoaded(state) {
        return state.cartStatus === 'success';
    },
    totalCartVisits(state, getters) {
        return getters.isCartLoaded && state.cart && state.cart.length;
    },
    isDiscounted(state, getters, store, rootGetters) {
        if (getters.isCartLoaded) {
            const count = rootGetters['Facility/totalPrerequisiteVisits'];
            if (count === 0) return true; // always apply discount

            return count && count === getters.totalCartVisits;
        } else return false;
    },
    // This is a very specific case for when schedule-payment should be displaying cart visits
    isCartReady(state, getters, store, rootGetters) {
        if (getters.isCartLoaded) {
            const count = rootGetters['Facility/totalPrerequisiteVisits'];

            return getters.totalCartVisits > count;
        } else return false;
    },
    maxDiscountIntervals(state, getters, store, rootGetters) {
        const count = rootGetters['Facility/totalPrerequisiteVisits'];
        if (count === 0) return ARBITRARY_HIGH_NUMBER;

        // Can only select min([allVisitIntervals]) for the discounted visit.
        // This only compares non-discounted visits. If the array does have a discounted visit, then
        // we take a Craigish approach and just use a very high number (effectively skipping it).
        return (
            getters.totalCartVisits &&
            Math.min(
                ...state.cart.map((visit) =>
                    visit.is_discounted
                        ? ARBITRARY_HIGH_NUMBER
                        : visit.intervals
                )
            )
        );
    },
    shoppingCartTotalCost(state, getters) {
        return (
            getters.isCartLoaded &&
            state.cart
                .map((visit) => visit.fee.toFixed(2))
                .reduce(
                    (previous, current) => Number(previous) + Number(current),
                    0
                )
                .toFixed(2)
        );
    },
    cartTransactionFeesTotalCost(state, getters) {
        if (getters.totalCartVisits) {
            const totals = state.cart.map((visit) =>
                visit.transaction_fees.reduce(
                    (previous, item) => Number(previous) + Number(item.amount),
                    0
                )
            );

            return parseFloat(
                totals.reduce(
                    (previous, current) => Number(previous) + Number(current),
                    0
                )
            ).toFixed(2);
        } else return 0;
    },
};

const actions = {
    cartRequest: ({ commit }) => {
        commit('cartRequest');

        const request = api.v2.cart.index();
        request
            .then((resp) => {
                commit('cartSuccess', resp.data.data);
            })
            .catch(() => {
                commit('cartError');
            });

        return request;
    },

    addToCartRequest: ({ commit }, data) => {
        commit('cartRequest');

        const request = api.v2.cart.store(data);
        request
            .then((resp) => {
                commit('cartSuccess', resp.data.data);
            })
            .catch(() => {
                commit('cartError');
            });

        return request;
    },

    clearCartRequest: ({ commit }) => {
        commit('cartRequest');

        const request = api.v2.cart.clear();
        request
            .then((resp) => {
                commit('clearCartSuccess', resp.data.data);
            })
            .catch(() => {
                commit('cartError');
            });

        return request;
    },
};

const mutations = {
    cartRequest: (state) => {
        state.cartStatus = 'loading';
    },
    cartSuccess: (state, resp) => {
        state.cartStatus = 'success';
        state.cart = resp;
    },
    clearCartSuccess: (state, resp) => {
        state.cartStatus = 'success';
        state.cart = resp;
        state.isAddingVisit = false;
    },
    cartError: (state) => {
        state.cartStatus = 'error';
    },
    updateIsAddingVisit(state, boolValue) {
        state.isAddingVisit = boolValue;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
