import api from '@/lib/api';

const state = {
    visitTypes: null,
    type: JSON.parse(sessionStorage.getItem('visitType')),
    status: 'loading',
};

const getters = {
    areVisitTypesLoaded: (state) => state.status === 'success',
    isOnsiteAtHome: (state) =>
        state.type && state.type.name.toLowerCase() === 'onsite at-home',
    isContactAtHome: (state) =>
        state.type &&
        state.type.name.toLowerCase() === 'onsite contact at-home',
};

const actions = {
    visitTypesRequest: ({ commit }) => {
        commit('visitTypeRequest');

        const request = api.v2.visitTypes();
        request
            .then((resp) => {
                commit('visitTypeSuccess', resp.data);
            })
            .catch(() => {
                commit('visitTypeError');
            });

        return request;
    },
};

const mutations = {
    visitTypeRequest: (state) => {
        state.status = 'loading';
    },
    visitTypeSuccess: (state, resp) => {
        state.status = 'success';
        state.visitTypes = resp;
    },
    visitTypeError: (state) => {
        state.status = 'failure';
    },
    updateType: (state, type) => {
        state.type = state.visitTypes.find(
            (t) => t.name.toLowerCase() === type.toLowerCase()
        );

        // to prevent refreshing page from forgetting the type when scheduling
        sessionStorage.setItem('visitType', JSON.stringify(state.type));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
