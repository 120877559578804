<template>
    <div class="modal-backdrop">
        <div
            class="modal-dialog modal-dialog-centered modal-lg mobile-modal-min-width"
            role="document"
        >
            <div class="modal-content">
                <header class="modal-header">
                    <slot name="header">
                        <h4 class="modal-title">
                            <slot name="modal-title"></slot>
                        </h4>

                        <button
                            type="button"
                            class="btn btn-link btn-close rounded-circle ml-3"
                            @click="close"
                        >
                            <i class="fas fa-xmark"></i>
                        </button>
                    </slot>
                </header>
                <section v-if="hasBodySlot" class="modal-body">
                    <slot name="modal-body"></slot>
                </section>
                <footer v-if="hasFooterSlot" class="modal-footer">
                    <slot name="footer"></slot>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['close'],
    computed: {
        hasFooterSlot() {
            return this.hasSlot('footer');
        },
        hasBodySlot() {
            return this.hasSlot('modal-body');
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },

        hasSlot(name) {
            return !!this.$slots[name] || !!this.$slots[name];
        },
    },
};
</script>

<style>
.modal-backdrop {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3) !important;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.modal-dialog {
    max-height: 90vh;
}

.modal-content {
    max-height: 90vh;
    min-width: 500px;
    overflow-x: auto;
}

@media screen and (max-width: 575px) {
    .modal-content {
        max-height: 90vh;
        min-width: revert;
        margin-right: 15px;
        overflow-x: auto;
    }
}
</style>
