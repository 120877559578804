import api from '@/lib/api';

const state = {
    countries: null,
    countriesStatus: 'loading',
    states: null,
    statesStatus: 'loading',
};

const getters = {
    isCountriesLoaded: (state) => state.countriesStatus === 'success',
    isStatesLoaded: (state) => state.statesStatus === 'success',
};

const actions = {
    countriesRequest: ({ commit }) => {
        commit('countriesRequest');

        const request = api.v2.support.countries();
        request
            .then((resp) => {
                commit('countriesSuccess', resp.data.data);
            })
            .catch(() => {
                commit('countriesError');
            });

        return request;
    },
    statesRequest: ({ commit }) => {
        commit('statesRequest');

        const request = api.v2.support.states();
        request
            .then((resp) => {
                commit('statesSuccess', resp.data.data);
            })
            .catch(() => {
                commit('statesError');
            });

        return request;
    },
};

const mutations = {
    countriesRequest: (state) => {
        state.countriesStatus = 'loading';
    },
    countriesSuccess: (state, resp) => {
        state.countriesStatus = 'success';
        state.countries = resp;
    },
    countriesError: (state) => {
        state.countriesStatus = 'error';
    },
    statesRequest: (state) => {
        state.statesStatus = 'loading';
    },
    statesSuccess: (state, resp) => {
        state.statesStatus = 'success';
        state.states = resp;
    },
    statesError: (state) => {
        state.statesStatus = 'error';
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
