<template>
    <footer v-if="shouldShow">
        <div class="row">
            <div class="col-12 footer-top">
                <ul>
                    <li>
                        <router-link
                            class="text-primary"
                            :to="{
                                name:
                                    addMessagingRouting +
                                    'acceptable-use-policy',
                                hash: '#anchor',
                            }"
                        >
                            Acceptable Use Policy
                        </router-link>
                    </li>
                    <li v-if="isMessagingApp">
                        <router-link
                            class="text-primary"
                            :to="{
                                name: 'iwebvisit-touch.usage-policy',
                                hash: '#anchor',
                            }"
                        >
                            iWebTouch Usage Policy
                        </router-link>
                    </li>
                    <li v-if="!isMessagingApp">
                        <router-link
                            :to="{ name: 'testimonials', hash: '#anchor' }"
                            >More Testimonials</router-link
                        >
                    </li>
                    <li>
                        <router-link
                            :to="{
                                name: isMessagingApp
                                    ? 'iwebvisit-touch.recommendations'
                                    : 'system-requirements',
                                hash: '#anchor',
                            }"
                        >
                            {{
                                isMessagingApp
                                    ? 'Recommendations'
                                    : 'System Requirements'
                            }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            :to="{
                                name: addMessagingRouting + 'refund-policy',
                                hash: '#anchor',
                            }"
                            >Refund & Cancellation Policy</router-link
                        >
                    </li>
                    <a
                        :href="`${publicPath}docs/terms-of-use-2020.pdf`"
                        target="_blank"
                    >
                        Terms of Use
                    </a>
                    <!-- Once again ... disabling
                    <li>
                        <router-link
                            :to="{ name: 'test-my-equipment', hash: '#anchor' }"
                            >Test My Equipment</router-link
                        >
                    </li>
                    -->
                </ul>
            </div>

            <div class="col-12 footer-bottom">
                <p class="mb-0">
                    &copy; Copyright iWebVisit.com, LLC
                    {{ new Date().getFullYear() }}. All rights reserved
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import { routing } from '@/mixins/routing';

export default {
    mixins: [routing],
    data() {
        return {
            publicPath: process.env.BASE_URL,
        };
    },
    computed: {
        shouldShow() {
            return this.$route.name !== 'iwebvisit-touch.download-app';
        },
    },
    created() {
        const url = window.location.href;

        // Adding Zopim chat when on the live site.
        if (!url.includes('localhost') && !url.includes('127.0.0.1')) {
            window.$zopim ||
                (function (d, s) {
                    var $zopim;
                    // eslint-disable-next-line no-unused-vars
                    var z = ($zopim = function (c) {
                            z._.push(c);
                        }),
                        $ = (z.s = d.createElement(s)),
                        e = d.getElementsByTagName(s)[0];
                    z.set = function (o) {
                        z.set._.push(o);
                    };
                    z._ = [];
                    z.set._ = [];
                    $.async = !0;
                    $.setAttribute('charset', 'utf-8');
                    $.src =
                        'https://v2.zopim.com/?2rdWKCI8fFTBqPPZmBnLvZ2h97Bgep1Q';
                    z.t = +new Date();
                    $.type = 'text/javascript';
                    e.parentNode.insertBefore($, e);
                })(document, 'script');
        }
    },
};
</script>
