export const routing = {
    computed: {
        /** This file is using vue-router route param globally */
        isScheduling() {
            return (
                this.$route.name === 'schedule-inmate' ||
                this.$route.name === 'schedule-datetime' ||
                this.$route.name === 'schedule-payment'
            );
        },
        isMessagingApp() {
            return this.$route.path.indexOf('iwebvisit-touch') > -1;
        },
        addMessagingRouting() {
            return this.isMessagingApp ? 'iwebvisit-touch.' : '';
        },
        queryParams() {
            return new URLSearchParams(window.location.search);
        },
    },
    methods: {
        scrollToElement(element) {
            const el = document.getElementById(element);

            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        },
    },
};
