import api from '@/lib/api';

const state = {
    facilities: null,
    facilitiesStatus: 'loading',
};

const getters = {
    isFacilitiesLoaded: (state) => state.facilitiesStatus === 'success',
    isFacilityReady: (state, getters) =>
        getters.isFacilitiesLoaded && getters.preferredFacility,
    isItemsReady: (state, getters, store, rootGetters) =>
        getters.isFacilityReady && rootGetters['User/isProfileLoaded'],
    preferredFacility: (state, getters, store) => {
        if (!state.facilities || !store.User.profile) {
            return null;
        }

        const preferredFacility = state.facilities.filter((x) => {
            return x.id === store.User.profile.preferred_facility_id;
        });

        return preferredFacility.length > 0 ? preferredFacility[0] : null;
    },
    isFacilityDeactivated: (state, getters, store) =>
        store.User.profile.deactivated_facilities
            .map((f) => f.fac_id)
            .indexOf(store.User.profile.preferred_facility_id) > -1 ||
        !store.User.profile.is_active,
    canEditPhoneNumber: (state, getters, store) =>
        store.User.profile.is_active &&
        store.User.profile.deactivated_facilities &&
        store.User.profile.deactivated_facilities.length === 0,
    isPhotoIdsRequired: (state, getters) =>
        getters.isFacilitiesLoaded &&
        !!getters.preferredFacility.is_photo_ids_required,
    isApprovedDocumentsRequired: (state, getters) =>
        getters.isFacilitiesLoaded &&
        !!getters.preferredFacility.is_approved_documents_required,
    isApprovalRequired: (state, getters) =>
        getters.isFacilitiesLoaded &&
        getters.preferredFacility.fac_requires_visitor_approval,
    currentDiscount: (state, getters) =>
        getters.isItemsReady && getters.preferredFacility.discount,
    // Prerequisite visits are the # of visits needed to meet discount conditions
    totalPrerequisiteVisits: (state, getters) =>
        getters.currentDiscount && getters.currentDiscount.prerequisite_visits,

    //onsite types
    isOnsiteAllowed: (state, getters, store, rootGetters) =>
        getters.isItemsReady && rootGetters['User/memberType'].is_standard
            ? getters.preferredFacility.fac_allows_public_onsite &&
              getters.preferredFacility.onsite_standard_length > 0
            : getters.preferredFacility.fac_allows_public_onsite_confidential &&
              getters.preferredFacility.onsite_confidential_length > 0,
    isContactAllowed: (state, getters, store, rootGetters) =>
        getters.isItemsReady && rootGetters['User/memberType'].is_standard
            ? getters.preferredFacility.fac_allows_public_onsite_contact &&
              getters.preferredFacility.contact_standard_length > 0
            : getters.preferredFacility
                  .fac_allows_public_onsite_contact_confidential &&
              getters.preferredFacility.contact_confidential_length > 0,
    isOnsiteEnabled: (state, getters) =>
        getters.isOnsiteAllowed || getters.isContactAllowed,
    // The big clause at the bottom is for Solano (of course). They force the selection into
    // being a contact visit, since we have to offer a choice in general for confidentials
    // and JCDF always has contact visits, period. As of 2023-09-08.
    isContactOnly: (state, getters, store, rootGetters) =>
        (!getters.isOnsiteAllowed && getters.isContactAllowed) ||
        (getters.isItemsReady &&
            !!store.VisitType.type &&
            !!store.VisitType.type.is_onsite &&
            !!store.Schedule.inmate &&
            (store.Schedule.inmate.inm_housing.includes('JCDF-') ||
                (rootGetters['User/memberType'].is_confidential &&
                    store.Schedule.inmate.inm_housing.includes('SCF-')))),
    mustChooseOnsiteType: (state, getters) =>
        getters.isOnsiteAllowed &&
        getters.isContactAllowed &&
        !getters.isContactOnly,
    isShowingOnsiteChoices: (state, getters, store) =>
        !!store.VisitType.type &&
        !!store.VisitType.type.is_onsite &&
        getters.mustChooseOnsiteType,
    getOnsiteType: (state, getters, store, rootGetters) =>
        rootGetters['Schedule/isContactAtHome'] || getters.isContactOnly
            ? 'onsite contact at-home'
            : 'onsite at-home',
};

const actions = {
    facilitiesRequest: ({ commit }) => {
        commit('facilitiesRequest');

        const request = api.v2.facilities.index();
        request
            .then((resp) => {
                commit('facilitiesSuccess', resp.data);
            })
            .catch(() => {
                commit('facilitiesError');
            });

        return request;
    },

    // Note that this calls a profile route and updates the facility through that controller
    facilityUpdateRequest: ({ commit }, id) => {
        if (!id) {
            console.error('no id provided to facilityUpdateRequest');
            return;
        }

        const request = api.v2.profile.preferredFacility({
            facility: id,
        });
        request
            .then((resp) => {
                commit('User/userSuccess', resp.data.data, { root: true });
            })
            .catch((e) => {
                console.error(e);
                commit('User/userError', {}, { root: true });
            });

        return request;
    },
};

const mutations = {
    facilitiesRequest: (state) => {
        state.facilitiesStatus = 'loading';
    },
    facilitiesSuccess: (state, resp) => {
        state.facilitiesStatus = 'success';
        state.facilities = resp.data;
    },
    facilitiesError: (state) => {
        state.facilitiesStatus = 'error';
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
