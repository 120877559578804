export const messages = {
    data() {
        return {
            messages: {
                deactivated:
                    'Your visitation privileges have been revoked by the facility administrator. ' +
                    'You cannot schedule visits at this facility at this time.',
                underage:
                    'This facility does not allow minors to schedule visits',
                iOS:
                    "IOS (iPhone, iPad, iPod) devices must use Apple's Safari browser " +
                    'to conduct their video visitation.',
                samsung:
                    "Samsung A12 and A32 devices must use Mozilla's Firefox browser to " +
                    'conduct their video visitation.',
                additionalVisit:
                    'To get this promotional offer, you will need to add another visit. Please ' +
                    'fill out the visit details in the same manner as before, starting with the ' +
                    "inmate selection. After closing this dialog, simply select the 'Confirm Inmate' " +
                    'button if you want to visit the same inmate.',
                discountedVisit:
                    'You have met the discount requirements. Please fill out your discounted visit details, ' +
                    "starting with the inmate selection. After closing this dialog, simply select the 'Confirm Inmate' button " +
                    'if you want to visit the same inmate.<b> Please note the following terms</b>:<br><br>' +
                    '<ul class="text-left"><li>The discount will be applied during checkout.</li>' +
                    '<li>You may only select an interval amount that is equal to or less than the <b>LOWEST</b> ' +
                    'number of visit intervals previously selected in the disount offer.</li></ul>',
                chatHelpMessage:
                    'Start chat: <i class="far fa-comment text-primary"></i> *<br>' +
                    'Add Credits: <i class="far fa-credit-card text-primary"></i><br>' +
                    'Account Info: <i class="fas fa-circle-info text-primary"></i><br>' +
                    'Settings: <i class="fas fa-ellipsis text-primary"></i><br><br>' +
                    '* You must request approval with the inmate if not already ' +
                    'approved. You do this by selecting the inmate from the list and ' +
                    "tapping 'Request Approval?' If approved, you will be notified by email. " +
                    'Once approved, selecting an inmate will open the chat.<br><br>' +
                    'If you have an issue with the app or other questions, ' +
                    'please email support@iwebvisit.com. This help dialogue may be toggled ' +
                    'again in settings if needed.',
            },
        };
    },
};
