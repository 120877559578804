var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.shouldShow)?_c('footer',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 footer-top"},[_c('ul',[_c('li',[_c('router-link',{staticClass:"text-primary",attrs:{"to":{
                            name:
                                _vm.addMessagingRouting +
                                'acceptable-use-policy',
                            hash: '#anchor',
                        }}},[_vm._v(" Acceptable Use Policy ")])],1),(_vm.isMessagingApp)?_c('li',[_c('router-link',{staticClass:"text-primary",attrs:{"to":{
                            name: 'iwebvisit-touch.usage-policy',
                            hash: '#anchor',
                        }}},[_vm._v(" iWebTouch Usage Policy ")])],1):_vm._e(),(!_vm.isMessagingApp)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'testimonials', hash: '#anchor' }}},[_vm._v("More Testimonials")])],1):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":{
                            name: _vm.isMessagingApp
                                ? 'iwebvisit-touch.recommendations'
                                : 'system-requirements',
                            hash: '#anchor',
                        }}},[_vm._v(" "+_vm._s(_vm.isMessagingApp ? 'Recommendations' : 'System Requirements')+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                            name: _vm.addMessagingRouting + 'refund-policy',
                            hash: '#anchor',
                        }}},[_vm._v("Refund & Cancellation Policy")])],1),_c('a',{attrs:{"href":`${_vm.publicPath}docs/terms-of-use-2020.pdf`,"target":"_blank"}},[_vm._v(" Terms of Use ")])])]),_c('div',{staticClass:"col-12 footer-bottom"},[_c('p',{staticClass:"mb-0"},[_vm._v(" © Copyright iWebVisit.com, LLC "+_vm._s(new Date().getFullYear())+". All rights reserved ")])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }