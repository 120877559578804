import axios from 'axios';
import http from '@/http';

// Has no 'api/v2' prefix
const generalApi = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

// not authenticated
const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL + 'api/v2',
    timeout: 0,
});

import Cookies from 'js-cookie';

// Admin API routes
const adminApi = axios.create({
    baseURL: process.env.VUE_APP_API_URL + 'api/admin',
    headers: {
        Authorization: Cookies.get('admin_access_token'),
        'Content-Type': 'application/json;charset=utf-8',
        Accept: 'application/json',
    },
});

/** Be aware of whether 'api' or 'http' is being used. This is very important.
 *  Api is used for unauthenticated requests.
 *  http is for authenticated.
 *  TODO(Sounds like that should be swapped. As in, 'api' sounds like the var
 *      that should be used for authenticated routes, and 'http' the one for
 *      non (more general) routes, but maybe that's just me being a perfectionist.)
 */
export default {
    broadcastAuth(channel) {
        return {
            authorize: (socketId, callback) => {
                generalApi
                    .post(
                        '/broadcasting/auth',
                        {
                            socket_id: socketId,
                            channel_name: channel.name,
                        },
                        {
                            headers: {
                                Authorization:
                                    window.http.defaults.headers.common
                                        .Authorization,
                                'Content-Type':
                                    'application/json;charset=utf-8',
                                Accept: 'application/json',
                            },
                        }
                    )
                    .then((response) => {
                        callback(null, response.data);
                    })
                    .catch((error) => {
                        callback(error);
                    });
            },
        };
    },

    csrf() {
        return generalApi.get('/sanctum/csrf-cookie');
    },

    v2: {
        approval: {
            request(data) {
                return http.post('/approvals/email', data);
            },
        },
        auth: {
            email(data) {
                return api.post('/password/email', data);
            },
            inmate: {
                email(data) {
                    return api.post('/auth/inmates/password/email', data);
                },
                login(data) {
                    return api.post('auth/inmate-login', data);
                },
                logout() {
                    return http.post('auth/inmate-logout'); // NOTE: using http!!!
                },
                register(data) {
                    return api.post('/auth/inmates/register', data);
                },
                reset(data) {
                    return http.post('/auth/inmates/password/reset', data); // NOTE: using http!!!
                },
            },
            login(data) {
                return api.post('/login', data);
            },
            logout() {
                return http.post('/logout'); // NOTE: using http!!!
            },
            register(data) {
                return api.post('/register', data);
            },
            token(token) {
                return api.get('/password/reset/' + encodeURI(token));
            },
            reset(data) {
                return api.post('/password/reset', data);
            },
            testVisit(data) {
                return api.post('/test-visit', data);
            },
        },
        blocklists: {
            destroy(participantId, blocklistId) {
                return http.delete(
                    '/chat-participants/' +
                        participantId +
                        '/blocklists/' +
                        blocklistId
                );
            },
            store(participantId, data) {
                return http.post(
                    '/chat-participants/' + participantId + '/blocklists',
                    data
                );
            },
        },
        cart: {
            index() {
                return http.get('/shopping-cart');
            },
            store(data) {
                return http.post('/shopping-cart', data);
            },
            clear() {
                return http.delete('/shopping-cart/clear/cart');
            },
        },
        chats: {
            addChatMessage(id, data) {
                return http.post(
                    '/chat-participants/' + id + '/chat-messages',
                    data
                );
            },
            chatMessagesRead(id) {
                return http.put(
                    '/chat-participants/' + id + '/chat-messages/read'
                );
            },
            participantChats(id, type) {
                return http.get('/chats/model/' + id + '/type/' + type);
            },
            show(id, type) {
                return http.get('/chats/' + id + '/type/' + type);
            },
            store(data) {
                return http.post('/chats', data);
            },
            signalAudioCall(chatId, participantId) {
                return http.post(
                    '/chats/' +
                        chatId +
                        '/chat-participants/' +
                        participantId +
                        '/signal-audio-call'
                );
            },
            signalVideoCall(chatId, participantId) {
                return http.post(
                    '/chats/' +
                        chatId +
                        '/chat-participants/' +
                        participantId +
                        '/signal-video-call'
                );
            },
            callRejected(chatId, participantId, reason = null) {
                const data = { reason: reason };

                return http.put(
                    '/chats/' +
                        chatId +
                        '/chat-participants/' +
                        participantId +
                        '/call/rejected',
                    data
                );
            },
        },
        chatQuotas: {
            show(participantId, quotaId) {
                return http.get(
                    '/chat-participants/' +
                        participantId +
                        '/chat-quotas/' +
                        quotaId
                );
            },
            store(participantId, data) {
                return http.post(
                    '/chat-participants/' + participantId + '/chat-quotas',
                    data
                );
            },
            update(participantId, quotaId, data) {
                return http.put(
                    '/chat-participants/' +
                        participantId +
                        '/chat-quotas/' +
                        quotaId,
                    data
                );
            },
        },
        credits: {
            store(data) {
                return http.post('/credits', data);
            },
            transactionLogs(url) {
                return http.get(url);
            },
        },
        deviceTokens: {
            store(data) {
                return http.post('/device-tokens', data);
            },
            // Note that this uses the unique UUID field as an ID.
            update(token, data) {
                return http.put('/device-tokens/' + token, data);
            },
        },
        facilities: {
            index() {
                return http.get('/facilities');
            },
            inmates(facilityId) {
                return http.get('/facilities/' + facilityId + '/inmates');
            },
            terminalSchedules(token, id) {
                return api.get(
                    '/facilities/token/' +
                        token +
                        '/terminals/' +
                        id +
                        '/schedules'
                );
            },
        },
        inmateContacts: {
            delete(id) {
                return http.delete('/inmate-contacts/' + id);
            },
            index() {
                return http.get('/inmate-contacts');
            },
            show(id) {
                return http.get('/inmate-contacts/' + id);
            },
            update(id, data) {
                return http.put('/inmate-contacts/' + id, data);
            },
            store(data) {
                return http.post('/inmate-contacts', data);
            },
        },
        inmates: {
            availability(key, data) {
                return http.post('/inmates/' + key + '/availability', data);
            },
            dates(key, data) {
                return http.post('/inmates/' + key + '/dates', data);
            },
            intervals(key, data) {
                return http.post('/inmates/' + key + '/intervals', data);
            },
            iWebTouchAvailability(key, data) {
                return http.post(
                    '/inmates/' + key + '/iwebtouch/availability',
                    data
                );
            },
            times(key, data) {
                return http.post('/inmates/' + key + '/times', data);
            },
        },
        profile: {
            show() {
                return http.get('/profile');
            },
            preferredFacility(data) {
                return http.post('/profile/preferred-facility', data);
            },
            update(data) {
                return http.post('/profile', data);
            },
            exists(data) {
                return http.post('/profile/exists', data);
            },
        },
        pushSubscriptions: {
            // This is both create and update, handled by backend laravel
            // web push library.
            save(data) {
                return http.post('/push-subscriptions', data);
            },
            destroy(endpoint) {
                return http.delete('/push-subscriptions/' + btoa(endpoint));
            },
        },
        verification: {
            verify(data) {
                return http.post('/verify-code', data);
            },
            resend() {
                return http.post('/verify-code/resend');
            },
        },
        visits: {
            anticipate(data) {
                return http.post('/visits', data);
            },
            callAccepted(chatId, visitId, participantId) {
                return http.put(
                    '/chats/' +
                        chatId +
                        '/chat-participants/' +
                        participantId +
                        '/visits/' +
                        visitId +
                        '/call/accepted'
                );
            },
            callFinished(id, data) {
                return fetch(
                    process.env.VUE_APP_API_URL +
                        'api/v2/visits/' +
                        id +
                        '/call/finished',
                    {
                        method: 'PUT',
                        headers: {
                            Authorization:
                                window.http.defaults.headers.common
                                    .Authorization,
                            'Content-Type': 'application/json;charset=utf-8',
                            Accept: 'application/json',
                        },
                        body: JSON.stringify(data),
                        keepalive: true,
                    }
                );
            },
            cancel(id) {
                return http.put('/visits/' + id + '/cancel');
            },
            delete(id) {
                return http.delete('/visits/' + id);
            },
            index(url) {
                return http.get(url);
            },
            // 2022-03-28:  Covers scheduling routes if you are an invited visitor:
            invites(visitId, inviteId, data) {
                return http.post(
                    '/visits/' + visitId + '/invites/' + inviteId,
                    data
                );
            },

            // 2022-03-28:  Covers scheduling routes for primary visitor:
            //  1)  Normal Scheduling
            //  2)  Primary adding additional visitors
            //  3)  Scheduling when visits are in the cart.  NOTE that this route is HERE,
            //  //  and not in the 'cart' section
            schedule(url, data) {
                return http.post(url, data);
            },
            startAudioCall(visitId, chatId, participantId) {
                return http.post(
                    '/chats/' +
                        chatId +
                        '/chat-participants/' +
                        participantId +
                        '/visits/' +
                        visitId +
                        '/start-audio-call'
                );
            },
            startVideoCall(visitId, chatId, participantId) {
                return http.post(
                    '/chats/' +
                        chatId +
                        '/chat-participants/' +
                        participantId +
                        '/visits/' +
                        visitId +
                        '/start-video-call'
                );
            },
            visitPage: {
                index(participantId, isTestVisit) {
                    return api.get(
                        '/visit-page/' +
                            (participantId + (isTestVisit ? '?test=true' : ''))
                    );
                },
                token(token, isTestVisit) {
                    return api.get(
                        '/visit-page-token/' +
                            (token + (isTestVisit ? '?test=true' : ''))
                    );
                },
                startRecording(visitId, sessionId) {
                    return api.post(
                        '/visit/' + visitId + '/openvidu/' + sessionId
                    );
                },
            },
        },
        visitor: {
            photoIds: {
                index(visitorId) {
                    return http.get('/visitors/' + visitorId + '/photo-ids');
                },
            },
            documents: {
                index(visitorId) {
                    return http.get(
                        '/visitors/' + visitorId + '/approved-documents'
                    );
                },
            },
        },
        vouchers: {
            confirm(waiver, data) {
                return http.put('/vouchers/' + waiver + '/confirm', data);
            },
        },
        photoId: {
            show(id) {
                return http.get('/photo-ids/' + id);
            },
            create(data) {
                return http.post('/photo-ids', data);
            },
            update(id, data) {
                return http.put('/photo-ids/' + id, data);
            },
        },
        document: {
            show(id) {
                return http.get('/approved-documents/' + id);
            },
        },
        support: {
            countries() {
                return http.get('/support/countries');
            },

            states() {
                return http.get('/support/states');
            },
        },
        testimonials() {
            return http.get('/testimonials');
        },
        visitTypes() {
            return http.get('/visit-types');
        },
    },

    admin: {
        blocklists: {
            destroy(blocklistId) {
                return adminApi.delete('/blocklists/' + blocklistId);
            },
            store(data) {
                return adminApi.post('/blocklists', data);
            },
        },
        chats: {
            adminParticipantChats(participantId) {
                return adminApi.get(
                    '/chats/chat-participants/' + participantId
                );
            },
            show(chatId) {
                return adminApi.get('/chats/' + chatId);
            },
        },
        facilities: {
            chatKeywordSearch(facilityId, keyword) {
                return adminApi.get(
                    '/facilities/' + facilityId + '/chats/search/' + keyword
                );
            },
        },
        messages: {
            approve(messageId) {
                return adminApi.put('/chat-messages/' + messageId + '/approve');
            },
            deny(messageId) {
                return adminApi.put('/chat-messages/' + messageId + '/deny');
            },
        },
    },

    // If you need unauthenticated axios instance, import api and use like api.api.etc.
    // If you need access to 'protected' axios instance, you can call window.http directly.
    api,
};
