import { exists } from '@/mixins/exists';

export const errorHandling = {
    mixins: [exists],
    data() {
        return {
            serverError:
                'There was a problem with the server. Please try again later.',
            clientError:
                'There seems to be a problem with the input data. Either try ' +
                'again or refresh the page and try. We apologize for the inconvenience.',
            validationErrors: {},
        };
    },
    methods: {
        isValidationError(resp) {
            return (
                this.exists(resp.data) &&
                this.exists(resp.data.validation_errors)
            );
        },

        handleValidationErrors(errors) {
            errors.forEach((err) => {
                this.validationErrors[err.field] = err.errors;
            });
        },

        handleErrorLogging(error) {
            if (error.response) {
                let message = '';

                console.log(
                    'Error debugging:  Request made => server responded'
                );
                console.log('Status Code: ', error.response.status);

                if (error.response.data) {
                    if (error.response.data.message) {
                        message = error.response.data.message;
                    } else if (error.response.data.error) {
                        message = error.response.data.error;
                    } else {
                        message = error.message;
                    }
                }

                console.log('Error message: ', message);

                if (process.env.NODE_ENV === 'development') {
                    console.log('Data: ', error.response.data);
                    console.log('Headers: ', error.response.headers);
                }

                return message;
            } else if (error.request) {
                console.log(
                    'Error debugging:  The request was made but no response was received'
                );

                if (process.env.NODE_ENV === 'development') {
                    console.log(error.request);
                }

                return this.serverError;
            } else {
                console.log(
                    'Error debugging:  Something happened in setting up the request that triggered an Error'
                );
                console.log('Error', error.message);

                return this.clientError;
            }
        },

        /** Returns error message(s) that get displayed. */
        handleError(error) {
            let message;
            this.validationErrors = {};

            if (
                error &&
                error.response &&
                this.isValidationError(error.response)
            ) {
                this.handleValidationErrors(
                    error.response.data.validation_errors
                );

                message = 'Please fix the issues above before submitting.';
            } else {
                message = this.handleErrorLogging(error);
            }

            console.log(message);

            return message;
        },
        paymentValidation(postData) {
            if (!postData.firstName) {
                this.validationErrors.first_name = [];
                this.validationErrors.first_name.push(
                    'The first name field is required'
                );
            }
            if (!postData.lastName) {
                this.validationErrors.last_name = [];
                this.validationErrors.last_name.push(
                    'The last name field is required'
                );
            }
            if (!postData.billingAddress) {
                this.validationErrors.billing_address = [];
                this.validationErrors.billing_address.push(
                    'The billing address is required'
                );
            }
            if (!postData.country) {
                this.validationErrors.country = [];
                this.validationErrors.country.push(
                    'The country field is required'
                );
            }
            if (!postData.city) {
                this.validationErrors.city = [];
                this.validationErrors.city.push('The city field is required');
            }
            if (!postData.state) {
                this.validationErrors.state = [];
                this.validationErrors.state.push('The state field is required');
            }
            if (!postData.zip) {
                this.validationErrors.zip = [];
                this.validationErrors.zip.push(
                    'The zip code field is required'
                );
            }
            if (!postData.creditCardNumber) {
                this.validationErrors.credit_card_number = [];
                this.validationErrors.credit_card_number.push(
                    'The credit card number field is required'
                );
            }
            if (!postData.expirationDate) {
                this.validationErrors.expiration_date = [];
                this.validationErrors.expiration_date.push(
                    'The expiration date field is required'
                );
            }
            if (!postData.cvv) {
                this.validationErrors.cvv = [];
                this.validationErrors.cvv.push('The cvv field is required');
            }
        },
    },
};
