<template>
    <div class="normal-container py-5">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
                <div class="row align-items-end">
                    <div class="col-md-5 text-center mb-4 mx-auto">
                        <div
                            v-if="isSendingRequest"
                            class="login-page py-5 mb-5"
                        >
                            <div
                                class="spinner-border text-primary spinner-very-lg"
                                role="status"
                            ></div>
                        </div>
                        <div v-else class="login-page py-5 mb-5">
                            <form @submit.prevent="submit()">
                                <h3>Returning Visitor</h3>

                                <div
                                    v-for="(err, idx) in validationErrors.email"
                                    :key="'email-' + idx"
                                    :class="[invalidClasses]"
                                >
                                    {{ err }}
                                </div>
                                <div
                                    v-for="(
                                        err, idx
                                    ) in validationErrors.password"
                                    :key="'password-' + idx"
                                    :class="[invalidClasses]"
                                >
                                    {{ err }}
                                </div>

                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <span
                                            id="basic-addon1"
                                            class="input-group-text"
                                        >
                                            <img
                                                src="@assets/images/icon-username.png"
                                                alt=""
                                            />
                                        </span>
                                    </div>
                                    <input
                                        v-model="email"
                                        type="text"
                                        class="form-control"
                                        placeholder="Visitor ID or Email"
                                        aria-label="Visitor ID or Email"
                                        aria-describedby="basic-addon1"
                                    />
                                </div>

                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <span
                                            id="basic-addon2"
                                            class="input-group-text"
                                        >
                                            <img
                                                src="@assets/images/icon-password.jpg"
                                                alt=""
                                            />
                                        </span>
                                    </div>
                                    <input
                                        v-model="password"
                                        type="password"
                                        class="form-control"
                                        autocomplete="user-password"
                                        placeholder="Visitor Password"
                                        aria-label="Visitor Password"
                                        aria-describedby="basic-addon2"
                                    />
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div
                                                style="font-size: 15px"
                                                class="col-auto mr-1 pr-1"
                                            >
                                                <label
                                                    for="rememberLogin"
                                                    class="mb-1"
                                                    >Keep Me Logged In
                                                </label>
                                                <input
                                                    id="rememberLogin"
                                                    class="checkbox"
                                                    type="checkbox"
                                                    name="rememberLogin"
                                                    @change="
                                                        toggleRememberLogin
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-auto">
                                                <router-link
                                                    id="forgotPassword"
                                                    :to="{
                                                        name:
                                                            addMessagingRouting +
                                                            'auth.forgotten-password',
                                                        hash: '#top-anchor',
                                                    }"
                                                >
                                                    Forgot My Password
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-auto ml-auto">
                                        <button
                                            type="submit"
                                            class="btn btn-primary btn-lg"
                                        >
                                            Log In
                                        </button>
                                    </div>
                                </div>

                                <h2 class="mt-4">
                                    <router-link
                                        :to="{
                                            name:
                                                addMessagingRouting +
                                                'create-account',
                                            hash: '#anchor',
                                        }"
                                    >
                                        Create New Visitor Account
                                    </router-link>
                                </h2>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { errorHandling } from '@/mixins/error-handling';
import { notify } from '@/mixins/notify';
import { routing } from '@/mixins/routing';

export default {
    mixins: [errorHandling, notify, routing],
    props: {
        error: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isSendingRequest: false,
            email: '',
            password: '',
            invalidClasses: 'alert alert-danger text-left',
        };
    },
    computed: {
        ...mapGetters('Facility', ['preferredFacility']),
    },
    mounted() {
        if (this.error) {
            this.notify(this.handleError(this.error));
        }
    },
    methods: {
        submit() {
            this.isSendingRequest = true;
            this.loginRequest({
                email: this.email,
                password: this.password,
            })
                .then(() => {
                    if (this.isMessagingApp) {
                        this.facilitiesRequest()
                            .then(() => this.userRequest())
                            .then(() => {
                                if (
                                    !!this.preferredFacility &&
                                    this.preferredFacility
                                        .is_messaging_app_enabled
                                ) {
                                    this.$router.push({
                                        name: 'iwebvisit-touch.messaging.index',
                                    });
                                } else {
                                    this.$router.push({
                                        name: 'iwebvisit-touch.select-facility',
                                    });
                                }
                            });
                    } else {
                        this.$router.push({
                            name: 'my-account',
                            hash: '#anchor',
                        });
                    }

                    this.userRequest();
                    this.email = '';
                })
                .catch((err) =>
                    this.notify(this.handleError(err), { duration: 3000 }, true)
                )
                .finally(() => {
                    this.password = '';
                    this.isSendingRequest = false;
                });
        },
        ...mapMutations('Auth', [
            'toggleRememberLogin',
            'toggleOffRememberLogin',
        ]),
        ...mapActions('Auth', ['loginRequest']),
        ...mapActions('User', ['userRequest']),
        ...mapActions('Facility', ['facilitiesRequest']),
    },
};
</script>

<style scoped>
.spinner-very-lg {
    height: 15rem;
    width: 15rem;
}
</style>
