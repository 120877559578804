/** Just a note - the member types are left uppercase. Anything dealing with the visit
 *  types are usually handled in lowercase. The request data gets strtolower'ed on the
 *  server, but just mentioning */
// TODO(Does this still apply? Isn't it grabbing the member type object now and not a string?)

import api from '@/lib/api';

const state = {
    profile: null,
    status: 'loading',
};

const getters = {
    isProfileLoaded: (state) => state.status === 'success',
    isVerified: (state) => !!state.profile.verified_at,
    memberType: (state, getters) =>
        getters.isProfileLoaded && state.profile.member_type,
    // TODO(Make sure birthdate can handle international dates?)
    birthdate: (state, getters) =>
        getters.isProfileLoaded && new Date(state.profile.mem_birth + ' '),
    // TODO(Make sure this in relation to birthdate can handle international dates)
    /** Subtracts the facility's min age from now. Returns a date. */
    minimumAge: (state, getters, store, rootGetters) =>
        rootGetters['Facility/isFacilityReady'] &&
        new Date(
            new Date().setMonth(
                getters.birthdate.getMonth() -
                    12 * rootGetters['Facility/preferredFacility'].minimum_age
            )
        ),
    isUnderage: (state, getters) => getters.birthdate > getters.minimumAge,
    /** Resource:  Photo IDs */
    isPhotoIdUploaded: (state, getters) =>
        getters.isProfileLoaded && !!state.profile.photo_id,
    isPhotoIdRequired: (state, getters, store, rootGetters) =>
        rootGetters['Facility/isPhotoIdsRequired'] &&
        !getters.isPhotoIdUploaded,
    isPhotoIdUpdatable: (state, getters, store, rootGetters) =>
        rootGetters['Facility/isPhotoIdsRequired'] &&
        !!getters.isPhotoIdUploaded,
    /** Resource:  Approved Documents */
    isApprovedDocumentsUploaded: (state, getters) =>
        getters.isProfileLoaded && !!state.profile.approved_document,
    isApprovedDocumentsRequired: (state, getters, store, rootGetters) =>
        rootGetters['Facility/isApprovedDocumentsRequired'] &&
        !getters.isApprovedDocumentsUploaded,
    isApprovedDocumentsUpdatable: (state, getters, store, rootGetters) =>
        rootGetters['Facility/isApprovedDocumentsRequired'] &&
        getters.isApprovedDocumentsUploaded,
    /** Resource:  Member Approval */
    isVisitorApproved: (state, getters, store) => {
        const approvals = state.profile.approvals.filter(
            (approval) =>
                approval ===
                (store.Schedule.inmate
                    ? store.Schedule.inmate.inm_number
                    : store.Schedule.visit.inmate_number)
        );

        return getters.isProfileLoaded && approvals && approvals.length;
    },
    isApproved: (state, getters, store, rootGetters) => {
        // TODO(Handle this at some point)
        if (
            rootGetters['Cart/isCartLoaded'] &&
            rootGetters['Cart/totalCartVisits']
        ) {
            return true; // Inmate modal selection should at least stop from adding non-approved for now
        }

        return (
            rootGetters['Facility/isItemsReady'] &&
            rootGetters['Facility/isApprovalRequired'] &&
            (store.Schedule.inmate || store.Schedule.visit) &&
            getters.isVisitorApproved
        );
    },
    // TODO(Fix this madness one day. Note also this is not the same regex as on edit account)
    isPrimaryPhoneValid: (state, getters) =>
        getters.isProfileLoaded &&
        state.profile.day_phone &&
        /^\d{3}-\d{3}-\d{4}$/.exec(state.profile.day_phone),
    isValidDate: (state) => {
        if (!getters.isProfileLoaded) return false;
        if (!state.profile.mem_birth) return false;

        const dateString = state.profile.mem_birth;
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (dateString && !dateString.match(regEx)) return false; // Invalid format

        const d = new Date(dateString);
        const unix = d.getTime();
        if (!unix && unix !== 0) return false; // NaN value, Invalid date

        return d.toISOString().slice(0, 10) === dateString;
    },
};

const actions = {
    userRequest: ({ commit }) => {
        commit('userRequest');

        const request = api.v2.profile.show();
        request
            .then((resp) => {
                commit('userSuccess', resp.data.data);
            })
            .catch(() => {
                commit('userError');
            });

        return request;
    },
    // The inverse of userRequest, but no actual web request
    removeUser: ({ commit }) => {
        commit('userRemove');
        return Promise.resolve();
    },
};

const mutations = {
    userRequest: (state) => {
        state.status = 'loading';
    },
    userSuccess: (state, resp) => {
        state.status = 'success';
        state.profile = resp;
    },
    userError: (state) => {
        state.status = 'error';
    },
    userRemove: (state) => {
        state.status = 'loading';
        state.profile = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
