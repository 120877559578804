export const animations = {
    methods: {
        onElementViewportEnter(element, animation, threshold) {
            const observer = this.getElementIntersectionObserver(
                element,
                animation,
                threshold
            );

            observer.observe(element);
        },
        getElementIntersectionObserver(element, className, threshold) {
            return new window.IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        element.className = className;
                    } else {
                        element.className = '';
                    }
                },
                {
                    root: null,
                    threshold: threshold, // at least this percent of element is in viewport
                }
            );
        },
    },
};
