var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isCancelling)?_c('div',{staticClass:"bg-very-light py-4 px-sm-5 px-3 my-5 rounded text-center"},[_c('div',{staticClass:"spinner-border text-primary spinner-lg",attrs:{"role":"status"}})]):(Array.isArray(_vm.sortedVisits) && _vm.sortedVisits.length > 0)?_c('table',{staticClass:"table small"},[_c('tr',[_c('th',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleInmateSort.apply(null, arguments)}}},[_vm._v("Inmate")])]),_c('th',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleTimeSort.apply(null, arguments)}}},[_vm._v("Time")])]),_c('th',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleLengthSort.apply(null, arguments)}}},[_vm._v("Length")])]),_c('th',{staticClass:"text-primary actions-th"},[_vm._v("Actions")])]),_vm._l((_vm.sortedVisits),function(visit){return _c('tr',{key:visit.id,class:visit.is_onsite
                    ? 'text-color-blue'
                    : _vm.isInvite(visit)
                    ? 'text-success'
                    : ''},[_c('td',[_vm._v(" "+_vm._s(visit.inmate_name)+" "),_c('br'),_vm._v(" @"+_vm._s(visit.facility.name)+" ")]),_c('td',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(visit.start_date)+" "),_c('br'),_vm._v(" "+_vm._s(visit.start_time + ' ' + visit.facility.timezone.short)+" ")]),_c('td',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(visit.length)+" mins "),_c('br'),_vm._v(" "+_vm._s('(' + visit.intervals + ' interval' + (visit.intervals > 1 ? 's' : '') + ')')+" ")]),_c('td',[(visit.status === 'completed')?_c('div',{staticClass:"btn-group mt-0 mt-sm-1",attrs:{"role":"group"}},[_c('button',{class:[
                            _vm.actionButtonSizing,
                            'btn visit-actions btn-link',
                        ],attrs:{"type":"button"},on:{"click":function($event){return _vm.handleCompleted(visit)}}},[_vm._v(" Status ")]),(_vm.hasInfo(visit))?_c('button',{class:[
                            _vm.actionButtonSizing,
                            'btn visit-actions btn-link pl-0',
                        ],attrs:{"type":"button"},on:{"click":function($event){return _vm.handleInfo(visit)}}},[_vm._v(" Info ")]):_vm._e()]):(visit.status === 'cancelled')?_c('div',{staticClass:"btn-group mt-0 mt-sm-1",attrs:{"role":"group"}},[_c('button',{class:[
                            _vm.actionButtonSizing,
                            'btn visit-actions btn-link pl-0',
                        ],attrs:{"type":"button"},on:{"click":function($event){return _vm.handleInfo(visit)}}},[_vm._v(" Info ")])]):_c('div',{staticClass:"py-1 py-md-3 py-lg-2"},[(_vm.isRemote(visit))?_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[(_vm.isPayable(visit))?_c('button',{class:[
                                _vm.actionButtonSizing,
                                'btn visit-actions btn-link',
                            ],attrs:{"type":"button"},on:{"click":function($event){return _vm.handlePayable(visit)}}},[_vm._v(" Pay ")]):(_vm.isVisitCountingDown(visit))?_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.countdownString(visit))+" "),(_vm.isInvitable(visit))?_c('button',{class:[
                                    _vm.visitTableButtons,
                                    _vm.actionButtonSizing,
                                    'btn visit-actions btn-link ml-1 ml-sm-2',
                                ],attrs:{"type":"button"},on:{"click":function($event){return _vm.addVisitors(visit)}}},[_vm._v(" Invite ")]):_vm._e()]):(_vm.isJoinable(visit))?_c('button',{class:[
                                _vm.visitTableButtons,
                                'btn btn-secondary btn-block p-1 p-sm-2 my-2 my-sm-1 my-md-0 ml-0',
                            ],attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.startVisit(visit)}}},[_vm._v(" Start Visit ")]):_c('div',[(visit.is_dormant)?_c('div',[_c('p',[_vm._v("visit expired")])]):_c('div',[(_vm.isPayable(visit))?_c('button',{class:[
                                        _vm.actionButtonSizing,
                                        'btn visit-actions btn-link',
                                    ],attrs:{"type":"button"},on:{"click":function($event){return _vm.handlePayable(visit)}}},[_vm._v(" Pay ")]):_vm._e(),(_vm.isInvitable(visit))?_c('button',{class:[
                                        _vm.visitTableButtons,
                                        _vm.actionButtonSizing,
                                        'btn visit-actions btn-link',
                                    ],attrs:{"type":"button"},on:{"click":function($event){return _vm.addVisitors(visit)}}},[_vm._v(" Invite ")]):_vm._e(),(_vm.isCancellable(visit))?_c('button',{class:[
                                        _vm.actionButtonSizing,
                                        'btn visit-actions btn-link pl-0',
                                    ],attrs:{"type":"button"},on:{"click":function($event){return _vm.cancel(visit)}}},[_vm._v(" Cancel ")]):_vm._e(),(_vm.hasInfo(visit))?_c('button',{class:[
                                        _vm.actionButtonSizing,
                                        'btn visit-actions btn-link pl-0',
                                    ],attrs:{"type":"button"},on:{"click":function($event){return _vm.handleInfo(visit)}}},[_vm._v(" Info ")]):_vm._e()])])]):_c('div',[(_vm.isCancellable(visit))?_c('button',{class:[
                                _vm.actionButtonSizing,
                                'btn visit-actions btn-link pl-0',
                            ],attrs:{"type":"button"},on:{"click":function($event){return _vm.cancel(visit)}}},[_vm._v(" Cancel ")]):_vm._e()])])])])})],2):_c('p',{staticClass:"text-center text-primary"},[_vm._v("No scheduled visits")])])
}
var staticRenderFns = []

export { render, staticRenderFns }