import api from '@/lib/api';

/** Number of seconds ago to send as request. */
const THREE_MONTHS_AGO = 7776000;

const state = {
    visits: null,
    status: 'loading',
    // these are saved here b/c they don't work properly within the Vue data property.
    // See https://stackoverflow.com/questions/44427686/clear-interval-doesnt-work-in-vue
    timeoutId: null,
    intervalId: null,
};

const getters = {
    isVisitsLoaded: (state) =>
        state.status === 'success' || state.status === 'reloading',
};

const actions = {
    visitsRequest: ({ commit }, data = null) => {
        commit('visitsRequest', !!data);

        const request = api.v2.visits.index(
            '/visits?timestamp=' + THREE_MONTHS_AGO
        );
        request
            .then((resp) => {
                commit('visitsSuccess', resp.data);
            })
            .catch(() => {
                commit('visitsError');
            });

        return request;
    },

    /** Timers */
    setTimeoutId: ({ commit }, id) => {
        commit('setTimeoutId', id);
    },

    setIntervalId: ({ commit }, id) => {
        commit('setIntervalId', id);
    },

    clearTimers: ({ commit }) => {
        commit('clearTimers');
    },
};

const mutations = {
    visitsRequest: (state, isReloading = false) => {
        state.status = isReloading ? 'reloading' : 'loading';
    },
    visitsSuccess: (state, resp) => {
        state.status = 'success';
        state.visits = resp.data;
    },
    visitsError: (state) => {
        state.status = 'error';
    },

    /** Timers */
    setTimeoutId: (state, id) => {
        state.timeoutId = id;
    },
    setIntervalId: (state, id) => {
        state.intervalId = id;
    },
    clearTimers: (state) => {
        clearTimeout(state.timeoutId);
        clearInterval(state.intervalId);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
