const state = {
    isNavOpen: false,
};

const mutations = {
    toggleNav: (state) => (state.isNavOpen = !state.isNavOpen),
    toggleNavClosed: (state) => (state.isNavOpen = false),
};

export default {
    namespaced: true,
    state,
    mutations,
};
