import Cookies from 'js-cookie';
import api from '@/lib/api';

const state = {
    accessToken: Cookies.get('access_token') || '',
    isShowingMobileLogin: false,
    isRememberingLogin: false,
};

const getters = {
    isAuthenticated: (state) => !!state.accessToken,
};

// Even though these request do almost the same thing, it's still better to split them
const actions = {
    loginRequest({ commit, dispatch }, data) {
        commit('authRequest');

        const requestData = {
            ...data,
            device_name: Math.random().toString(36),
        };
        const isInmate = new URLSearchParams(window.location.search).has(
            'inmate_id'
        );
        const request = isInmate
            ? api.v2.auth.inmate.login(requestData)
            : api.v2.auth.login(requestData);
        request
            .then((resp) => dispatch('authPromise', resp))
            .catch((err) => {
                commit('authError');
                const token = isInmate ? 'inmate_access_token' : 'access_token';
                // TODO(remove this eventually)
                Cookies.remove(token, {
                    domain: '.iwebvisit.com',
                });
                Cookies.remove(token);

                return Promise.reject(err);
            });

        return request;
    },

    registerRequest({ commit, dispatch }, data) {
        commit('authRequest');

        const request = api.v2.auth.register({
            ...data,
            device_name: Math.random().toString(36),
        });

        request
            .then((resp) => dispatch('authPromise', resp))
            .catch((err) => {
                commit('authError');
                // TODO(remove this eventually)
                Cookies.remove('access_token', {
                    domain: '.iwebvisit.com',
                });
                Cookies.remove('access_token');

                return Promise.reject(err);
            });

        return request;
    },

    resetRequest({ commit, dispatch }, data) {
        commit('authRequest');

        const request = api.v2.auth.reset({
            ...data,
            device_name: Math.random().toString(36),
        });
        request
            .then((resp) => dispatch('authPromise', resp))
            .catch((err) => {
                commit('authError');
                // TODO(remove this eventually)
                Cookies.remove('access_token', {
                    domain: '.iwebvisit.com',
                });
                Cookies.remove('access_token');

                return Promise.reject(err);
            });

        return request;
    },

    logoutRequest({ commit }) {
        const isInmate = new URLSearchParams(window.location.search).has(
            'inmate_id'
        );
        const token = isInmate ? 'inmate_access_token' : 'access_token';
        // TODO(remove this eventually)
        Cookies.remove(token, {
            domain: '.iwebvisit.com',
        });
        Cookies.remove(token);

        commit('authRequest');
        const request = isInmate
            ? api.v2.auth.inmate.logout()
            : api.v2.auth.logout();
        request
            .then(() => commit('authLogout'))
            .catch((err) => {
                commit('authError');

                return Promise.reject(err);
            });

        return request;
    },

    authPromise({ commit }, resp) {
        if (resp.data.status && resp.data.status !== 200) {
            throw resp;
        }

        let token;
        let accessToken;

        if (new URLSearchParams(window.location.search).has('inmate_id')) {
            token = 'inmate_access_token';
            accessToken = 'Bearer ' + resp.data.data.token;
        } else {
            token = 'access_token';
            accessToken = 'Bearer ' + resp.data.token;
        }

        // TODO(Remove this eventually)
        Cookies.remove(token, {
            domain: 'iwebvisit.com',
        });
        Cookies.remove(token, {
            domain: 'www.iwebvisit.com',
        });

        const url = window.location.href;

        if (url.includes('localhost') || url.includes('127.0.0.1')) {
            if (state.isRememberingLogin)
                Cookies.set('access_token', accessToken, { expires: 365 });
            else Cookies.set(token, accessToken, { expires: 1 });
        } else {
            if (state.isRememberingLogin) {
                Cookies.set('access_token', accessToken, {
                    expires: 365,
                    domain: '.iwebvisit.com',
                });
            } else {
                Cookies.set(token, accessToken, {
                    expires: 1,
                    domain: '.iwebvisit.com',
                });
            }
        }

        window.http.defaults.headers.common['Authorization'] = accessToken;
        commit('authSuccess', accessToken);

        return accessToken;
    },
};

const mutations = {
    authRequest(state) {
        state.status = 'Logging in..';
    },

    authSuccess(state, token) {
        state.accessToken = token;
        state.hasLoadedOnce = true;
        state.status = 'Logged in.';
    },

    authError: (state) => {
        state.accessToken = '';
        state.status = 'error';
    },

    authLogout: (state) => {
        state.accessToken = '';
    },

    toggleMobileHeaderLogin: (state) =>
        (state.isShowingMobileLogin = !state.isShowingMobileLogin),
    toggleOffMobileHeaderLogin: (state) => (state.isShowingMobileLogin = false),

    toggleRememberLogin: (state) =>
        (state.isRememberingLogin = !state.isRememberingLogin),
    toggleOffRememberLogin: (state) => (state.isRememberingLogin = false),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
