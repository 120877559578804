import { messages } from '@/mixins/messages';

export const detector = {
    mixins: [messages],
    computed: {
        // IOS devices currently require safari as the web browser during video visitation.
        isIosDevice() {
            return this.isMostIosDevices || this.isIpadForIos13;
        },
        isIpadForIos13() {
            return (
                navigator.userAgent.includes('Mac') && 'ontouchend' in document
            );
        },
        // TODO(navigator.platform is deprecated)
        isMostIosDevices() {
            return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform);
        },
        // Samsung A12 and A32 devices currently require firefox as the web browser during video visitation
        isSamsungDeviceTypeA() {
            return navigator.userAgent.match(/SM-A/i);
        },
        // Returns the appropriate message, based on device type.
        deviceMessage() {
            switch (true) {
                case this.isIosDevice:
                    return this.messages.iOS;
                case this.isSamsungDeviceTypeA:
                    return this.messages.samsung;
                default:
                    return '';
            }
        },
    },
};
