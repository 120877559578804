<template>
    <div
        :class="[
            isAuthenticated
                ? 'nav-box col-8 offset-2 col-sm-6 offset-sm-3 col-md-12 offset-md-0'
                : '',
        ]"
    >
        <div
            v-if="shouldShow"
            :class="[isShowingMobileLogin ? 'header-login-active' : null]"
        >
            <a
                class="mobile-header-login"
                @click.prevent="toggleMobileHeaderLogin"
            >
                <h3>Login</h3>
            </a>
            <div class="header-login header-login-width">
                <a class="close" @click.prevent="toggleMobileHeaderLogin">
                    <i class="fas fa-xmark fa-lg mt-3 mr-2"></i>
                </a>
                <form @submit.prevent="submit()">
                    <h3>Returning Visitor</h3>

                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <span
                                id="input-group-text-username"
                                class="input-group-text"
                            >
                                <img
                                    alt=""
                                    src="@assets/images/icon-username.png"
                                />
                            </span>
                        </div>
                        <input
                            v-model="email"
                            type="text"
                            class="form-control"
                            placeholder="Visitor Email"
                            aria-label="Visitor Email"
                            aria-describedby="input-group-text-username"
                        />
                    </div>

                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <span
                                id="input-group-text-password"
                                class="input-group-text"
                            >
                                <img
                                    alt=""
                                    src="@assets/images/icon-password.jpg"
                                />
                            </span>
                        </div>
                        <input
                            v-model="password"
                            type="password"
                            class="form-control"
                            autocomplete="user-password"
                            placeholder="Visitor Password"
                            aria-label="Visitor Password"
                            aria-describedby="input-group-text-password"
                        />
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col-auto remember-login mr-1 pr-1">
                                    <label for="rememberLogin" class="mb-1"
                                        >Keep Me Logged In
                                    </label>
                                    <input
                                        id="rememberLogin"
                                        class="checkbox"
                                        type="checkbox"
                                        name="rememberLogin"
                                        @change="toggleRememberLogin"
                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div
                                    class="col-auto"
                                    @click="toggleOffMobileHeaderLogin"
                                >
                                    <router-link
                                        id="forgotPassword"
                                        :to="{
                                            name:
                                                addMessagingRouting +
                                                'auth.forgotten-password',
                                            hash: '#top-anchor',
                                        }"
                                        >Forgot My Password</router-link
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="col-auto ml-auto">
                            <button
                                type="submit"
                                class="btn btn-primary btn-lg"
                            >
                                Log In
                            </button>
                        </div>
                    </div>

                    <h2
                        class="mt-2 ml-lg-n1"
                        @click="toggleOffMobileHeaderLogin"
                    >
                        <router-link
                            :to="{
                                name: addMessagingRouting + 'create-account',
                                hash: '#anchor',
                            }"
                            >Create New Visitor Account</router-link
                        >
                    </h2>
                </form>
            </div>
        </div>
        <div v-else-if="isAuthenticated && profile && !isMessagingApp">
            <div
                class="header-login-logged-in header-login-logged-in-text-align"
            >
                <h5 class="login-text-size ellipses">
                    {{ profile.mem_email }}
                </h5>
                <div class="desktop-only">
                    <h5 class="mt-4">
                        <router-link
                            class="btn btn-primary btn-rounded mr-1"
                            :to="{ name: 'my-account', hash: '#anchor' }"
                        >
                            My Account
                        </router-link>
                        <a
                            class="btn btn-outline-primary btn-rounded mb-1"
                            @click.prevent="logout()"
                        >
                            Log Out
                        </a>
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { routing } from '@/mixins/routing';
import { errorHandling } from '@/mixins/error-handling';
import { notify } from '@/mixins/notify';

export default {
    mixins: [routing, errorHandling, notify],
    data() {
        return {
            email: '',
            password: '',
        };
    },
    computed: {
        ...mapState('Auth', ['isShowingMobileLogin']),
        ...mapState('User', ['profile']),
        ...mapGetters('Auth', ['isAuthenticated']),
        ...mapGetters('Facility', ['preferredFacility']),

        shouldShow() {
            return (
                !this.isAuthenticated &&
                this.$route.name !== this.addMessagingRouting + 'auth.login' &&
                this.$route.name !== 'iwebvisit-touch.download-app'
            );
        },
    },
    mounted() {
        if (this.isAuthenticated && !this.profile) {
            this.userRequest();
        }
    },
    methods: {
        ...mapMutations('Auth', [
            'toggleMobileHeaderLogin',
            'toggleOffMobileHeaderLogin',
            'toggleRememberLogin',
            'toggleOffRememberLogin',
        ]),
        ...mapActions('Auth', ['loginRequest', 'logoutRequest']),
        ...mapActions('User', ['userRequest']),
        ...mapActions('User', ['removeUser']),
        ...mapActions('Facility', ['facilitiesRequest']),

        submit() {
            this.loginRequest({
                email: this.email,
                password: this.password,
            })
                .then(() => {
                    if (this.isMessagingApp) {
                        this.facilitiesRequest()
                            .then(() => this.userRequest())
                            .then(() => {
                                if (
                                    !!this.preferredFacility &&
                                    this.preferredFacility
                                        .is_messaging_app_enabled
                                ) {
                                    this.$router.push({
                                        name: 'iwebvisit-touch.messaging.index',
                                    });
                                } else {
                                    this.$router.push({
                                        name: 'iwebvisit-touch.select-facility',
                                    });
                                }
                            });
                    } else {
                        this.$router.push({
                            name: 'my-account',
                            hash: '#anchor',
                        });
                    }

                    this.userRequest();
                    this.email = '';
                })
                .catch((err) => {
                    if (
                        this.$route.name !==
                        this.addMessagingRouting + 'auth.login'
                    ) {
                        this.$router.push({
                            name: this.addMessagingRouting + 'auth.login',
                            params: { error: err },
                        });
                    }
                })
                .finally(() => {
                    this.password = '';
                });
        },
        logout() {
            this.logoutRequest().then(() => {
                this.$swal('You have been successfully logged out.');

                if (this.$route.name !== this.addMessagingRouting + 'index') {
                    this.$router
                        .push({ name: this.addMessagingRouting + 'index' })
                        .then(() => this.removeUser());
                } else {
                    this.removeUser();
                }
            });
        },
    },
};
</script>
