<template>
    <main v-show="isReady" class="content-wrapper">
        <div class="container-fluid">
            <header-component></header-component>
            <div v-if="!isScheduling" id="anchor"></div>

            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>

            <footer-component></footer-component>
        </div>
    </main>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent';
import FooterComponent from '@/components/FooterComponent';
import { routing } from '@/mixins/routing';

export default {
    components: {
        FooterComponent,
        HeaderComponent,
    },
    mixins: [routing],
    data() {
        return {
            isReady: false,
        };
    },
    mounted() {
        setTimeout(() => (this.isReady = true), 250);
    },
};
</script>
