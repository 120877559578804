<template>
    <div>
        <div
            v-if="isCancelling"
            class="bg-very-light py-4 px-sm-5 px-3 my-5 rounded text-center"
        >
            <div
                class="spinner-border text-primary spinner-lg"
                role="status"
            ></div>
        </div>
        <table
            v-else-if="Array.isArray(sortedVisits) && sortedVisits.length > 0"
            class="table small"
        >
            <tr>
                <th>
                    <a href="#" @click.prevent="handleInmateSort">Inmate</a>
                </th>
                <th>
                    <a href="#" @click.prevent="handleTimeSort">Time</a>
                </th>
                <th>
                    <a href="#" @click.prevent="handleLengthSort">Length</a>
                </th>
                <th class="text-primary actions-th">Actions</th>
            </tr>

            <tr
                v-for="visit in sortedVisits"
                :key="visit.id"
                :class="
                    visit.is_onsite
                        ? 'text-color-blue'
                        : isInvite(visit)
                        ? 'text-success'
                        : ''
                "
            >
                <td>
                    {{ visit.inmate_name }} <br />
                    @{{ visit.facility.name }}
                </td>
                <td class="text-primary">
                    {{ visit.start_date }}
                    <br />
                    {{ visit.start_time + ' ' + visit.facility.timezone.short }}
                </td>
                <td class="text-primary">
                    {{ visit.length }} mins
                    <br />
                    {{
                        '(' +
                        visit.intervals +
                        ' interval' +
                        (visit.intervals > 1 ? 's' : '') +
                        ')'
                    }}
                </td>
                <td>
                    <div
                        v-if="visit.status === 'completed'"
                        class="btn-group mt-0 mt-sm-1"
                        role="group"
                    >
                        <button
                            type="button"
                            :class="[
                                actionButtonSizing,
                                'btn visit-actions btn-link',
                            ]"
                            @click="handleCompleted(visit)"
                        >
                            Status
                        </button>
                        <button
                            v-if="hasInfo(visit)"
                            type="button"
                            :class="[
                                actionButtonSizing,
                                'btn visit-actions btn-link pl-0',
                            ]"
                            @click="handleInfo(visit)"
                        >
                            Info
                        </button>
                    </div>
                    <div
                        v-else-if="visit.status === 'cancelled'"
                        class="btn-group mt-0 mt-sm-1"
                        role="group"
                    >
                        <button
                            type="button"
                            :class="[
                                actionButtonSizing,
                                'btn visit-actions btn-link pl-0',
                            ]"
                            @click="handleInfo(visit)"
                        >
                            Info
                        </button>
                    </div>
                    <div v-else class="py-1 py-md-3 py-lg-2">
                        <div
                            v-if="isRemote(visit)"
                            class="btn-group"
                            role="group"
                        >
                            <button
                                v-if="isPayable(visit)"
                                type="button"
                                :class="[
                                    actionButtonSizing,
                                    'btn visit-actions btn-link',
                                ]"
                                @click="handlePayable(visit)"
                            >
                                Pay
                            </button>
                            <div
                                v-else-if="isVisitCountingDown(visit)"
                                class="d-flex align-items-center"
                            >
                                {{ countdownString(visit) }}
                                <button
                                    v-if="isInvitable(visit)"
                                    type="button"
                                    :class="[
                                        visitTableButtons,
                                        actionButtonSizing,
                                        'btn visit-actions btn-link ml-1 ml-sm-2',
                                    ]"
                                    @click="addVisitors(visit)"
                                >
                                    Invite
                                </button>
                            </div>
                            <button
                                v-else-if="isJoinable(visit)"
                                type="button"
                                :class="[
                                    visitTableButtons,
                                    'btn btn-secondary btn-block p-1 p-sm-2 my-2 my-sm-1 my-md-0 ml-0',
                                ]"
                                @click.prevent="startVisit(visit)"
                            >
                                Start Visit
                            </button>
                            <div v-else>
                                <div v-if="visit.is_dormant">
                                    <p>visit expired</p>
                                </div>
                                <div v-else>
                                    <button
                                        v-if="isPayable(visit)"
                                        type="button"
                                        :class="[
                                            actionButtonSizing,
                                            'btn visit-actions btn-link',
                                        ]"
                                        @click="handlePayable(visit)"
                                    >
                                        Pay
                                    </button>

                                    <button
                                        v-if="isInvitable(visit)"
                                        type="button"
                                        :class="[
                                            visitTableButtons,
                                            actionButtonSizing,
                                            'btn visit-actions btn-link',
                                        ]"
                                        @click="addVisitors(visit)"
                                    >
                                        Invite
                                    </button>
                                    <button
                                        v-if="isCancellable(visit)"
                                        type="button"
                                        :class="[
                                            actionButtonSizing,
                                            'btn visit-actions btn-link pl-0',
                                        ]"
                                        @click="cancel(visit)"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        v-if="hasInfo(visit)"
                                        type="button"
                                        :class="[
                                            actionButtonSizing,
                                            'btn visit-actions btn-link pl-0',
                                        ]"
                                        @click="handleInfo(visit)"
                                    >
                                        Info
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <!-- Onsite Visit Options, i.e. you can only cancel them -->
                            <button
                                v-if="isCancellable(visit)"
                                type="button"
                                :class="[
                                    actionButtonSizing,
                                    'btn visit-actions btn-link pl-0',
                                ]"
                                @click="cancel(visit)"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <p v-else class="text-center text-primary">No scheduled visits</p>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { sizing } from '@/mixins/sizing';
import { notify } from '@/mixins/notify';
import { COUNTDOWN_MINUTES, ADDITIONAL_VISITORS_LIMIT } from '@/lib/helper';
// Sometimes the timer is not exact, and the API pings every minute.
// This is just a large enough buffer that's not going to launch it 1 minute early.
// For example, if the API updates, and it's 3 seconds before the start time, when we subtract
// 30 seconds from that and compare against SF UTC, it will know to launch.
const BUFFER_SECONDS = 30;

export default {
    name: 'VisitsTable',
    mixins: [sizing, notify],
    props: ['filteredVisits', 'filter'],
    data() {
        return {
            now: null,
            timer: null,
            isCancelling: false,
            isInfoModalOpen: false,
            isCompletedModalOpen: false,
            selectedVisit: null,

            // handle sorting
            isSortingAscInmate: false,
            isSortingAscTime: true, // the default
            isSortingAscLength: false,
            whichSort: '',
        };
    },
    computed: {
        ...mapState('User', ['profile']),
        ...mapGetters('User', ['isProfileLoaded']),
        ...mapGetters('Facility', ['preferredFacility']),
        ...mapGetters('Schedule', ['isSubscription']),

        /** SORTING */
        visitsSortedByTime() {
            return this.filteredVisits.slice().sort((a, b) => {
                return this.comparator(
                    a.scheduled_for_utc,
                    b.scheduled_for_utc,
                    this.isSortingAscTime
                );
            });
        },
        visitsSortedByInmateName() {
            return this.filteredVisits.slice().sort((a, b) => {
                return this.comparator(
                    a.inmate_last + a.inmate_first,
                    b.inmate_last + b.inmate_first,
                    this.isSortingAscInmate
                );
            });
        },
        visitsSortedByLength() {
            return this.filteredVisits.slice().sort((a, b) => {
                return this.comparator(
                    a.length,
                    b.length,
                    this.isSortingAscLength
                );
            });
        },
        sortedVisits() {
            if (this.whichSort === 'inmate') {
                return this.visitsSortedByInmateName;
            } else if (this.whichSort === 'time') {
                return this.visitsSortedByTime;
            } else if (this.whichSort === 'length') {
                return this.visitsSortedByLength;
            } else {
                return this.filteredVisits;
            }
        },
        /** END SORTING */

        /** The following deal with specific visit objects */
        isRemote() {
            return (visit) => {
                return visit.is_remote;
            };
        },
        // TODO(We have visit.is_paid_for vs. an invite.is_paid. Would be better to have the same property names.)
        /** The check with 'upcoming' is to handle some cases where the timers aren't exact???
         *  Either way, if it's early a second or so, this will catch it. */
        isJoinable() {
            return (visit) => {
                return (
                    (visit.status === 'ongoing' ||
                        (visit.status === 'upcoming' &&
                            this.now >
                                visit.scheduled_for_utc - BUFFER_SECONDS)) &&
                    !visit.is_dormant &&
                    visit.is_paid_for &&
                    (this.isPrimaryVisitor || this.invite(visit).is_paid)
                );
            };
        },
        /** Triggers a visible timer so people will have feedback */
        isVisitCountingDown() {
            return (visit) => {
                return (
                    this.now >
                        visit.scheduled_for_utc - COUNTDOWN_MINUTES * 60 &&
                    this.now < visit.scheduled_for_utc
                );
            };
        },
        // For invites
        isPayable() {
            return (visit) => {
                return (
                    visit.status === 'upcoming' &&
                    this.now < visit.scheduled_for_utc &&
                    visit.is_paid_for &&
                    this.isInvite(visit) &&
                    !this.invite(visit).is_paid
                );
            };
        },
        /** You may invite under the following conditions:
         *  1) Visit must have been paid for
         *  2) You must be the primary visitor
         *  3) X minutes before visit start (depends on field in member_types table).
         *  4) Remote visit
         *  5) No FFH visits
         *  6) No discounted visits
         *  7) Must not exceed total # of visit participants allowed
         *  8) ??? Visit must have a fee ???
         * */
        isInvitable() {
            return (visit) => {
                return (
                    visit.is_paid_for &&
                    this.isPrimaryVisitor(visit) &&
                    this.now <
                        visit.scheduled_for_utc -
                            visit.member_type.visit_invitation_buffer_minutes *
                                60 &&
                    visit.is_remote &&
                    !visit.is_ffh &&
                    !visit.is_discounted &&
                    visit.invites &&
                    visit.invites.length < ADDITIONAL_VISITORS_LIMIT
                    // && visit.fee > 0
                );
            };
        },
        isCancellable() {
            return (visit) => {
                return (
                    visit.status === 'upcoming' && this.isPrimaryVisitor(visit)
                );
            };
        },
        hasInfo() {
            return (visit) => {
                return (
                    (visit.invites && visit.invites.length) ||
                    (this.isInvite(visit) &&
                        visit.waiver_codes &&
                        visit.waiver_codes.length) ||
                    (this.isPrimaryVisitor(visit) &&
                        visit.cancellation_waiver &&
                        visit.cancellation_waiver !== 'no_waiver_code') ||
                    visit.is_discounted
                );
            };
        },
        /**
         * The difference in seconds up until the visit is joinable, in string format.
         */
        countdownString() {
            return (visit) => {
                if (!this.isVisitCountingDown(visit)) {
                    return 'N/A';
                }

                let t = visit.scheduled_for_utc - this.now;

                if (t <= 0) {
                    return '00:00';
                }

                let minutes = Math.floor(t / 60).toLocaleString('en-US', {
                    minimumIntegerDigits: 1,
                    useGrouping: false,
                });
                let seconds = Math.floor(t % 60).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                });

                return `${minutes}:${seconds}`;
            };
        },
        isPrimaryVisitor() {
            return (visit) => {
                return (
                    this.isProfileLoaded &&
                    this.profile.mem_id === visit.visitor_id
                );
            };
        },
        isInvite() {
            return (visit) => {
                return (
                    this.isProfileLoaded &&
                    this.profile.mem_id !== visit.visitor_id
                );
            };
        },
        invite() {
            return (visit) => {
                return (
                    this.isInvite(visit) &&
                    visit.invites.find(
                        (visitor) => visitor.inv_mem_id === this.profile.mem_id
                    )
                );
            };
        },
        /** END specific visit objects */
    },
    watch: {
        isInfoModalOpen(i) {
            if (i) {
                const visit = this.selectedVisit;
                const wasCancelled = visit.vst_cancelled_timestamp;
                let html = '';

                if (wasCancelled) {
                    html =
                        '<span class="text-secondary">This visit was cancelled.</span>';
                }

                const waiverText = this.getWaiverHtml(visit);
                if (waiverText) html += '<br><br>' + waiverText;

                if (visit.invites_status && visit.invites_status.length) {
                    if (html) html += '<br><br>';
                    html +=
                        '<b>Invited Visitors</b>:<br><br>' +
                        visit.invites_status
                            .map((v) => v.info + ' - <b>' + v.status + '</b>')
                            .join('<br>');
                }

                if (visit.is_discounted) {
                    if (html) html += '<br><br>';
                    html += '<b>Discount applied</b>:  ' + visit.discount.name;
                }

                if (wasCancelled) {
                    if (html) html += '<br><br>';
                    html +=
                        '<b>Cancellation Policy</b>:<br><br>' +
                        'Visits are pre-paid and <b>non-refundable</b>. For standard visitors, when you cancel a visit at <b>least 4 hours</b> in advance, ' +
                        'a pre-paid iWebVisit.com waiver code, <b>valid for 30 days</b>, is issued to enable you to schedule ' +
                        'another visit through iWebVisit.com at no charge. Visits may also be cancelled at any time by a facility administrator, in which case ' +
                        'you may be eligible for a waiver code.';
                }

                if (!html) {
                    html = 'No relevant visit details';
                }

                this.$swal
                    .fire({
                        title: 'Visit Info',
                        icon: 'info',
                        html: html,
                        width: this.wideModalSizing,
                    })
                    .then(() => {
                        this.selectedVisit = null;
                        this.isInfoModalOpen = false;
                    });
            }
        },
        isCompletedModalOpen(i) {
            if (i) {
                const visit = this.selectedVisit;
                let html =
                    'This visit is either completed or currently ongoing. Visits are automatically marked as ' +
                    'completed after the purchased time has elapsed. Any visit is subject to manual termination ' +
                    'by a facility administrator prior to the scheduled visit end time.';

                if (visit.is_standard) {
                    const mins = visit.facility.visit_dormancy;
                    html +=
                        '<br><br>Participants have up until ' +
                        mins +
                        ' minutes after the scheduled start time to join the visit. If the visit is not joined within these ' +
                        mins +
                        ' minutes, it will be marked as completed. Visits must be cancelled at least 4 hours before' +
                        ' the scheduled start time to be eligible for rescheduling at no cost.';
                }

                this.$swal
                    .fire({
                        title: 'Visit Completed',
                        icon: 'success',
                        html: html,
                        width: this.wideModalSizing,
                    })
                    .then(() => {
                        this.selectedVisit = null;
                        this.isCompletedModalOpen = false;
                    });
            }
        },
        filter(f) {
            if (f && ['all', 'completed', 'cancelled'].includes(f)) {
                this.isSortingAscTime = false;
                this.whichSort = 'time';
            } else if (f && f === 'pending') {
                this.isSortingAscTime = true;
                this.whichSort = 'time';
            }
        },
    },
    created() {
        this.now = Math.floor(Date.now() / 1000);
        this.timer = setInterval(() => {
            this.now = Math.floor(Date.now() / 1000);
        }, 1000); // time updated every second
    },
    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        ...mapMutations('Schedule', ['updateFacility', 'updateVisit']),
        ...mapActions('Visits', ['visitsRequest']),
        ...mapActions('Facility', ['facilityUpdateRequest']),
        ...mapActions('Schedule', ['cancelVisitRequest']),

        /** Modals */
        handleInfo(visit) {
            this.isInfoModalOpen = true;
            this.selectedVisit = visit;
        },
        handleCompleted(visit) {
            this.isCompletedModalOpen = true;
            this.selectedVisit = visit;
        },
        /** END Modals */

        /** SORTING */
        handleInmateSort() {
            this.isSortingAscInmate = !this.isSortingAscInmate;
            this.whichSort = 'inmate';
        },
        handleTimeSort() {
            this.isSortingAscTime = !this.isSortingAscTime;
            this.whichSort = 'time';
        },
        handleLengthSort() {
            this.isSortingAscLength = !this.isSortingAscLength;
            this.whichSort = 'length';
        },
        comparator(first, second, isSortingAsc) {
            if (isSortingAsc) {
                return first > second ? 1 : -1;
            } else {
                return first < second ? 1 : -1;
            }
        },
        /** END SORTING */
        startVisit(visit) {
            if (visit.participant_token) {
                this.updateVisit(visit);
                this.$router.push({
                    name: 'visit-page',
                    params: { id: visit.participant_token },
                });
            }
        },
        async addVisitors(visit) {
            if (this.preferredFacility.id !== visit.facility.id) {
                await this.facilityUpdateRequest(visit.facility.id);

                // Let the visitor know we just changed the preferred facility
                this.notify(
                    'Updating preferred facility to ' +
                        this.preferredFacility.name,
                    3000,
                    true
                );

                this.updateFacility(this.preferredFacility);
            }

            this.updateVisit(visit);
            this.$router.push({
                name: 'schedule-payment',
                hash: '#anchor',
                params: { role: 'additional' },
            });
        },
        handlePayable(visit) {
            this.updateVisit(visit);
            this.$router.push({
                name: 'schedule-payment',
                hash: '#anchor',
                params: {
                    inviteId: this.invite(visit).inv_id,
                    role: 'invite',
                },
            });
        },
        cancel(visit) {
            let message = 'Are you sure you want to cancel the transaction?';

            if (visit.is_remote && !visit.is_cancellable) {
                if (visit.waiver_code && visit.waiver_code.startsWith('s')) {
                    message +=
                        ' Your subscription will not be reimbursed in this case due to the cancellation policy.';
                } else {
                    message +=
                        ' If you cancel now, your visit will NOT be reimbursed with a waiver code due to the' +
                        ' cancellation policy.';
                }
            }

            this.$swal
                .fire({
                    icon: 'warning',
                    text: message,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Cancel',
                    cancelButtonText: 'No, Go Back',
                    confirmButtonColor: '#ec4c24',
                    cancelButtonColor: '#213d76',
                })
                .then((value) => {
                    if (value.isConfirmed) {
                        this.isCancelling = true;
                        this.cancelVisitRequest(visit)
                            .then(() => this.visitsRequest())
                            .then(() => {
                                if (this.$route.name !== 'my-account') {
                                    this.$router.push({
                                        name: 'my-account',
                                        hash: '#schedule',
                                    });
                                }
                            })
                            .finally(() => (this.isCancelling = false));
                    } else {
                        return Promise.resolve();
                    }
                });
        },
        getWaiverHtml(visit) {
            let html = '';

            if (
                this.isInvite(visit) &&
                visit.waiver_codes &&
                visit.waiver_codes.length
            ) {
                const code = visit.waiver_codes.find(
                    (w) => w.cde_mem_id === this.profile.mem_id
                );

                if (code && code.cde_code) {
                    html = '<b>Waiver: ' + code.cde_code + '</b>';
                }
            } else if (
                this.isPrimaryVisitor(visit) &&
                visit.cancellation_waiver &&
                visit.cancellation_waiver !== 'no_waiver_code'
            ) {
                html = '<b>Waiver: ' + visit.cancellation_waiver + '</b>';
            }

            return html;
        },
    },
};
</script>

<style scoped>
.visit-actions {
    padding: 0.25em;
}

.actions-th {
    width: 22.5%;
}

@media screen and (max-width: 767px) {
    .visit-actions {
        padding: 0.15em;
    }

    .actions-th {
        width: 23%;
    }
}

@media screen and (max-width: 575px) {
    .visit-actions {
        padding: 0.1em;
    }
}

@media screen and (max-width: 390px) {
    .visit-actions {
        padding: 0.05em;
    }

    .actions-th {
        width: 25%;
    }
}
</style>
