// General methods that often need throughout projects.
// Trying to keep it DRY

export const exists = {
    methods: {
        exists(item) {
            return typeof item !== 'undefined' && item !== null;
        },

        arrayExists(item) {
            return Array.isArray(item) && item.length > 0;
        },
    },
};
