<template>
    <div class="row pt-md-2 mt-5">
        <div class="col-auto mb-lg-0 mb-5">
            <div :style="testimonialHeight">
                <transition name="fade" mode="out-in">
                    <div v-if="isShowing">
                        <p class="quote-text">
                            <span>
                                <img
                                    src="@assets/images/quote-before.png"
                                    alt=""
                                />
                            </span>
                            <span>
                                {{ currentTestimonial }}
                                <img
                                    src="@assets/images/quote-after.png"
                                    alt=""
                                />
                            </span>
                        </p>
                        <p class="quote-author">{{ currentSource }}</p>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { sizing } from '@/mixins/sizing';

export default {
    mixins: [sizing],
    data() {
        return {
            index: 0,
            interval: null,
            isShowing: false,
        };
    },
    computed: {
        ...mapState('Testimonial', ['testimonials']),
        ...mapGetters('Testimonial', ['areTestimonialsLoaded']),

        currentTestimonial() {
            return (
                this.testimonials && this.testimonials[this.index].description
            );
        },
        currentSource() {
            return this.testimonials && this.testimonials[this.index].source;
        },
        max() {
            return this.testimonials.length;
        },
        testimonialHeight() {
            return 'min-height: ' + (this.screenWidth > 575 ? '75vh' : '45vh');
        },
    },
    created() {
        if (this.areTestimonialsLoaded) {
            this.isShowing = true;
        } else {
            this.testimonialsRequest().then(() => (this.isShowing = true));
        }

        this.interval = setInterval(() => {
            this.isShowing = false;
            this.index = this.randomNumber();
            setTimeout(() => (this.isShowing = true), 750);
        }, 12000);
    },
    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    beforeDestroy() {
        clearInterval(this.interval);
    },
    methods: {
        ...mapActions('Testimonial', ['testimonialsRequest']),

        randomNumber() {
            return Math.floor(Math.random() * this.max);
        },
    },
};
</script>
