import Vue from 'vue';
import App from './App.vue';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import router from './router';
import store from './store';
import 'bootstrap';
// import '@/assets/scss/custom.scss';

Vue.config.productionTip = false;

import VueRecaptcha from 'vue-recaptcha';
import Toasted from 'vue-toasted';
import VueMask from 'v-mask';
Vue.use(Toasted);
Vue.use(VueMask, {
    placeholders: {
        '@': /[-/]/,
    },
});
Vue.use(VueRecaptcha);

// setup axios protected routes
import './http';

// vapor
window.Vapor = require('laravel-vapor');

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
