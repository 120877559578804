import api from '@/lib/api';

const state = {
    testimonials: null,
    status: 'loading',
};

const getters = {
    areTestimonialsLoaded: (state) => state.status === 'success',
};

const actions = {
    testimonialsRequest: ({ commit }) => {
        commit('testimonialRequest');
        const request = api.v2.testimonials();
        request
            .then((resp) => {
                commit('testimonialSuccess', resp.data);
            })
            .catch(() => {
                commit('testimonialError');
            });

        return request;
    },
};

const mutations = {
    testimonialRequest: (state) => {
        state.status = 'loading';
    },
    testimonialSuccess: (state, resp) => {
        state.status = 'success';
        state.testimonials = resp;
    },
    testimonialError: (state) => {
        state.status = 'failure';
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
