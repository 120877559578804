export const notify = {
    data() {
        return {
            toastOptions: {
                className: 'toast-attributes',
                containerClass: 'text-center',
                theme: 'outline',
                position: 'bottom-center',
                duration: 8000,
            },
        };
    },
    methods: {
        // Just message = message + default options
        // Message + options = Message + overridden options
        // Message + options + bool = message + default options + overridden options
        // Hope that makes sense!
        notify(message = '', options = this.toastOptions, isAppending = false) {
            const settings = isAppending
                ? {
                      ...this.toastOptions,
                      ...options,
                  }
                : options;

            this.$toasted.show(message, settings);
        },
    },
};
