<template>
    <header>
        <LoginBox />
        <div class="row">
            <div :class="[headerTopClass, 'col-12 header-top']">
                <div :class="[headerTopContainerClass, 'header-container']">
                    <router-link :to="{ name: addMessagingRouting + 'index' }">
                        <img
                            src="@assets/images/iweb-Logo.png"
                            alt="iWebVisit.com Logo"
                            class="logo"
                        />
                    </router-link>
                </div>
            </div>
            <template v-if="!isMessagingApp">
                <div class="col-12 header-bottom">
                    <div
                        :class="[
                            headerBottomContainerClass,
                            'header-container',
                        ]"
                    >
                        <nav-component class="main-navigation"></nav-component>
                    </div>
                </div>
                <div class="mobile-nav">
                    <!-- Mobile/Tablet burger menu w/ sidebar -->
                    <nav class="main-nav">
                        <Burger></Burger>
                    </nav>

                    <Sidebar>
                        <nav-component
                            :is-mobile="true"
                            class="sidebar-panel-nav"
                        ></nav-component>
                    </Sidebar>
                </div>
                <div v-if="isAuthenticated && !isScheduling">
                    <router-link
                        class="btn btn-sm btn-rounded schedule-now mobile-nav-schedule-btn"
                        :to="{ name: 'my-account', hash: '#schedule' }"
                        @click="scrollToElement('schedule')"
                    >
                        Schedule
                    </router-link>
                </div>
            </template>
        </div>
    </header>
</template>

<script>
import LoginBox from './Header/LoginBox';
import Sidebar from '@/components/Nav/Sidebar';
import Burger from '@/components/Nav/Burger';
import NavComponent from '@/components/Nav/NavComponent';
import { mapGetters } from 'vuex';
import { routing } from '@/mixins/routing';

export default {
    components: { NavComponent, LoginBox, Sidebar, Burger },
    mixins: [routing],
    computed: {
        ...mapGetters('Auth', ['isAuthenticated']),
        headerTopClass() {
            return this.isAuthenticated ? 'header-top-logged-in' : '';
        },

        headerTopContainerClass() {
            return !this.isAuthenticated
                ? 'header-container-not-logged-in'
                : '';
        },

        headerBottomContainerClass() {
            return this.isAuthenticated ? 'header-container-logged-in' : '';
        },
    },
};
</script>
