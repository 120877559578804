<template>
    <div class="sidebar">
        <div v-if="isNavOpen" class="sidebar-backdrop" @click="toggleNav"></div>
        <transition name="slide">
            <div v-if="isNavOpen" class="sidebar-panel pt-4">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';

export default {
    computed: {
        ...mapState('Nav', ['isNavOpen']),
    },
    methods: {
        ...mapMutations('Nav', ['toggleNav']),
    },
};
</script>
<style>
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s;
}
</style>
