import api from '@/lib/api';

const state = {
    inmateContacts: null,
    status: 'loading',
};

const getters = {
    areInmateContactsLoaded: (state) => state.status === 'success',
};

const actions = {
    inmateContactsRequest: ({ commit }) => {
        commit('inmateContactsRequest');
        const request = api.v2.inmateContacts.index();
        request
            .then((resp) => {
                commit('inmateContactSuccess', resp.data.data);
            })
            .catch(() => {
                commit('inmateContactError');
            });

        return request;
    },
    showInmateContactRequest: ({ commit }, id) => {
        commit('inmateContactsRequest');
        const request = api.v2.inmateContacts.show(id);
        request
            .then(
                () => {
                    commit('inmateContactCrudSuccess');
                },
                () => {
                    commit('inmateContactError');
                }
            )
            .catch(() => {
                commit('inmateContactError');
            });

        return request;
    },
    createInmateContactRequest: ({ commit }, data) => {
        commit('inmateContactsRequest');
        const request = api.v2.inmateContacts.store(data);
        request
            .then(() => {
                commit('inmateContactCrudSuccess');
            })
            .catch(() => {
                commit('inmateContactError');
            });

        return request;
    },
    updateInmateContactRequest: ({ commit }, { id, data }) => {
        commit('inmateContactsRequest');
        const request = api.v2.inmateContacts.update(id, data);
        request
            .then(
                () => {
                    commit('inmateContactCrudSuccess');
                },
                () => {
                    commit('inmateContactError');
                }
            )
            .catch(() => {
                commit('inmateContactError');
            });

        return request;
    },
    deleteInmateContactRequest: ({ commit }, id) => {
        commit('inmateContactsRequest');
        const request = api.v2.inmateContacts.delete(id);
        request
            .then(
                () => {
                    commit('inmateContactCrudSuccess');
                },
                () => {
                    commit('inmateContactError');
                }
            )
            .catch(() => {
                commit('inmateContactError');
            });

        return request;
    },
};

const mutations = {
    inmateContactsRequest: (state) => {
        state.status = 'loading';
    },
    inmateContactSuccess: (state, resp) => {
        state.status = 'success';
        state.inmateContacts = resp;
    },
    inmateContactError: (state) => {
        state.status = 'failure';
    },
    inmateContactCrudSuccess: (state) => {
        state.status = 'success';
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
