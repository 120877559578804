<template>
    <div>
        <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
        >
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img
                        class="d-block w-100"
                        src="@assets/images/updated-banner-1.jpg"
                        alt="First slide"
                    />
                </div>
                <div class="carousel-item">
                    <img
                        class="d-block w-100"
                        src="@assets/images/banner-3.jpg"
                        alt="Third slide"
                    />
                </div>
            </div>
            <a
                class="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
            >
                <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
            </a>
            <a
                class="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
            >
                <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

        <div class="normal-container py-5">
            <h1 class="text-center">
                iWebVisit.com Makes Technology Personal.
            </h1>
            <h2 class="text-secondary text-center">
                iWebVisit makes staying in touch and "seeing" your loved ones
                more affordable. The iWebVisit team cares...
            </h2>

            <div class="row mt-5 py-md-5">
                <div class="col-lg-3 col-md-6 mb-5 icon-col">
                    <img
                        src="@assets/images/icon-computer.png"
                        alt="iWebVisit.com - Stay at Home & Stay Connected"
                    />
                    <h3>Stay at Home &<br />Stay Connected</h3>
                </div>
                <div class="col-lg-3 col-md-6 mb-5 icon-col">
                    <img
                        src="@assets/images/icon-money.png"
                        alt="iWebVisit.com - Save Travel Time and Expense"
                    />
                    <h3>Save Travel Time<br />And Expense</h3>
                </div>
                <div class="col-lg-3 col-md-6 mb-5 icon-col">
                    <img
                        src="@assets/images/icon-calendar.png"
                        alt="iWebVisit.com - Schedule Visits Yourself, Today"
                    />
                    <h3>Schedule Visits<br />Yourself, Today</h3>
                </div>
                <div class="col-lg-3 col-md-6 mb-5 icon-col">
                    <img
                        src="@assets/images/icon-shield.png"
                        alt="iWebVisit.com - Safer & More Secure for Minor Visitors*"
                    />
                    <h3>Safer & More Secure<br />For Minor Visitors*</h3>
                </div>
            </div>

            <testimonial></testimonial>
        </div>
    </div>
</template>
<script>
import Testimonial from '@/components/Testimonial.vue';
export default {
    components: { Testimonial },
};
</script>
