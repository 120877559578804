import { ADDITIONAL_VISITORS_LIMIT, Helper } from '@/lib/helper';
import moment from 'moment';
import api from '@/lib/api';
import Vue from 'vue';

// TODO(Facility, inmate, and additionalVisitors shouldn't be here. Too much to clean up now.
//      Also possibly availability, which should be tied to the inmate)
const state = {
    availability: null,
    facility: null,
    inmate: null,
    inmates: null,
    date: null,
    intervals: null,
    time: null,
    voucher: null,
    isVoucherConfirmed: false,
    visitLocation: null,
    // The subscribers will sometimes have 0 fee visits; isFreeForSubscriptions tracks this, as well as
    // when a pay-per-use (WITH an actual fee) is cancelled. The next use has credits in this case.
    // It's all kinda dumb and has been a major pain to work with.
    isFreeForSubscription: false,
    additionalVisitors: [],

    // Sometimes scheduling as invited visitor, or the primary => paying later or
    // inviting more participants. The following deal with these cases.
    visit: null,
    additionalInvites: [],
};

const getters = {
    /** This first section is all string manipulation, for display */
    costForFree(state, getters, store) {
        if (!store.VisitType.type) return null;

        if (store.VisitType.type.is_onsite) {
            return 'Onsite, no fee';
        } else if (getters.isFreeFirstHour || !getters.pricePerInterval) {
            return 'Free';
        } else {
            return null;
        }
    },
    costPerInterval(state, getters, store) {
        if (!store.VisitType.type) return null;

        if (getters.pricePerInterval) {
            return '$' + getters.pricePerInterval.toFixed(2);
        } else {
            return null;
        }
    },
    intervalCost(state, getters) {
        return (
            getters.costPerInterval &&
            getters.costPerInterval +
                (state.intervals > 1
                    ? ' x ' + state.intervals + ' intervals'
                    : '')
        );
    },
    // 'visit' implies reaching payment page via MyAccount. 'availability' implies the normal route.
    additionalVisitorsCost(state, getters) {
        if (!state.visit && !state.availability) return null;

        const PLURAL = 2;

        if (
            (state.visit && getters.payingAdditionalInvitesCount < PLURAL) ||
            (state.availability && getters.payingAdditionalVisitorsCount === 0)
        )
            return null;

        let count = state.availability
            ? getters.payingAdditionalVisitorsCount + 1 // adding ourselves
            : getters.payingAdditionalInvitesCount;

        return ' x ' + count + ' Participants';
    },
    serviceFeeCost(state, getters) {
        return getters.isServiceFeeApplied
            ? ' + $' +
                  state.availability.customer_service_fee +
                  ' (service fee)'
            : '';
    },
    visitFee(state, getters) {
        if (getters.isFree) return getters.costForFree;

        // So basically, <MAIN COST> + <COST OF ADDITIONAL?> + <CSF?>
        if (getters.intervalCost) {
            return (
                getters.intervalCost +
                (getters.additionalVisitorsCost
                    ? getters.additionalVisitorsCost
                    : '') +
                (state.visit ? '' : getters.serviceFeeCost)
            );
        }

        return null;
    },
    visitDate(state) {
        if (state.date) {
            return moment(state.date.value).format('dddd MMMM Do, YYYY');
        } else if (state.visit) {
            return moment(state.visit.start_date).format('dddd MMMM Do, YYYY');
        } else {
            return null;
        }
    },
    visitLength(state, getters) {
        const intervals = getters.isFreeFirstHour
            ? state.facility.ffh_intervals
            : state.intervals;

        return (
            intervals &&
            getters.minutesPerInterval &&
            (getters.minutesPerInterval * intervals).toFixed(0)
        );
    },
    timeZone(state) {
        return state.facility && state.facility.timezone.nice;
    },
    inmateName(state) {
        return state.inmate && state.inmate.formatted_name;
    },
    isFreeFirstHour(state) {
        return !!state.date && !!state.date.is_free;
    },
    isFree(state, getters, store) {
        return (
            (!!store.VisitType.type && !!store.VisitType.type.is_onsite) ||
            getters.isFreeFirstHour ||
            !getters.pricePerInterval
        );
    },
    isWaived(state) {
        return state.isVoucherConfirmed;
    },
    isFreeOrWaived(state, getters) {
        return getters.isFree || getters.isWaived;
    },
    isSubscription(state, getters) {
        return (
            getters.isWaived && state.voucher && state.voucher.startsWith('s')
        );
    },
    isPayPerUse(state, getters) {
        return getters.isSubscription && state.voucher.startsWith('sp');
    },
    hasTransaction(state, getters) {
        return (
            !getters.isFreeOrWaived ||
            (getters.isPayPerUse && !state.isFreeForSubscription)
        );
    },
    // The backend is already checking the subscription status, but unfortunately, it won't know
    // about $visit->code?->is_subscription, since the record is not saved yet when scheduling.
    // So we do another check here to make sure they aren't a subscriber.
    isServiceFeeApplied(state, getters) {
        return (
            state.availability &&
            state.availability.is_service_fee_applied &&
            !getters.isSubscription
        );
    },
    /** The following are dealing with actual int/float values and not strings */
    visitorCost(state, getters) {
        return (
            state.intervals &&
            getters.pricePerInterval &&
            (state.intervals * getters.pricePerInterval).toFixed(2)
        );
    },
    payingAdditionalVisitorsCount(state) {
        const visitors =
            state.additionalVisitors &&
            state.additionalVisitors.filter((visitor) =>
                Helper.isUserPaying(visitor)
            );

        if (visitors) return visitors.length;
        else return 0;
    },
    // NOTE that this total cost is not taking into account transaction fees.
    // It was created before such. The components will tally the grand total
    // by adding this calculated total cost (which might include a CSF)
    // + transaction fees. I say this total cost, but it might be some of
    // the other "total cost" functions below.
    totalCost(state, getters, store) {
        if (
            !state.intervals ||
            (store.VisitType.type && store.VisitType.type.is_onsite)
        ) {
            return null;
        }

        if (getters.isFreeFirstHour) {
            return 0;
        }

        let csf = getters.isServiceFeeApplied
            ? state.availability.customer_service_fee
            : 0;

        const price = getters.pricePerInterval * state.intervals;
        return (
            price +
            csf +
            price * getters.payingAdditionalVisitorsCount
        ).toFixed(2);
    },
    inviteTotalCost(state, getters) {
        if (
            !state.intervals ||
            !state.visit ||
            getters.isFree ||
            !getters.pricePerInterval
        ) {
            return null;
        }

        return state.visit.per_visitor_cost.toFixed(2);
    },
    payingAdditionalInvitesCount(state) {
        const invites =
            state.additionalInvites &&
            state.additionalInvites.filter((visitor) =>
                Helper.isUserPaying(visitor)
            );

        if (invites) return invites.length;
        else return 0;
    },
    /** The cost when you are adding visitors from MyAccount */
    additionalInvitesTotalCost(state, getters) {
        if (
            !state.intervals ||
            !state.visit ||
            getters.isFree ||
            !getters.pricePerInterval
        ) {
            return null;
        }

        return (
            getters.pricePerInterval *
            state.intervals *
            getters.payingAdditionalInvitesCount
        ).toFixed(2);
    },
    minutesPerInterval(state, getters) {
        if (state.availability && state.availability.minutes_per_interval) {
            return state.availability.minutes_per_interval.toFixed(0);
        } else if (state.visit) {
            return getters.minutesPerIntervalFromFacility;
        } else {
            return null;
        }
    },
    minutesPerIntervalFromFacility(state) {
        if (state.visit && state.facility) {
            switch (true) {
                case state.visit.is_remote:
                    return state.facility.remote_standard_length;
                case state.visit.is_onsite && !state.visit.is_contact:
                    return state.visit.is_standard
                        ? state.facility.onsite_standard_length
                        : state.facility.onsite_confidential_length;
                case state.visit.is_contact:
                    return state.visit.is_standard
                        ? state.facility.contact_standard_length
                        : state.facility.contact_confidential_length;
            }
        } else return null;
    },
    pricePerInterval(state) {
        if (state.availability && 'price_per_interval' in state.availability) {
            return state.availability.price_per_interval;
        } else if (state.visit && 'price_per_interval' in state.visit) {
            return state.visit.price_per_interval;
        } else {
            return null;
        }
    },
    isUnderInviteLimit(state, getters) {
        return getters.totalInvites < ADDITIONAL_VISITORS_LIMIT;
    },
    totalInvites(state) {
        return state.additionalVisitors.length + state.additionalInvites.length;
    },
};

const actions = {
    inmatesRequest({ commit }, visit) {
        const inmateId = btoa(visit.facility.id + ':' + visit.inmate_number);
        const request = api.v2.facilities.inmates(visit.facility.id);

        request.then((response) => {
            let inmate = response.data.data.find(
                (inmate) => inmate.key === inmateId
            );

            return commit('updateInmate', inmate);
        });

        return request;
    },
    loadAvailability({ state, commit }, data) {
        console.log('loading availability');
        if (!state.inmate) {
            let message = 'No inmate found';
            console.error(message);

            return new Promise((resolve, reject) => reject(message));
        }

        const request = api.v2.inmates.availability(state.inmate.key, data);
        request.then((r) => commit('updateAvailability', r.data));

        return request;
    },
    // eslint-disable-next-line no-empty-pattern
    cancelVisitRequest({}, visit) {
        console.log('Cancelling Visit');
        const id = 'id' in visit ? visit.id : visit.vst_id;
        const request = api.v2.visits.cancel(id);

        request
            .then(() => {
                Vue.swal(
                    'Your visit with ' +
                        visit.inmate_name +
                        ' has been cancelled.'
                );
            })
            .catch(() => {
                Vue.swal(
                    'We were unable to process the cancellation of your visit, please try again.'
                );
            });

        return request;
    },
    // eslint-disable-next-line no-empty-pattern
    deleteVisitRequest({}, visit) {
        console.log('Deleting Anticipated Visit');
        const id = 'id' in visit ? visit.id : visit.vst_id;
        const request = api.v2.visits.delete(id);

        request.catch(() => {
            Vue.swal('Unable to cancel transaction');
        });

        return request;
    },
};

const mutations = {
    // TODO(This should be a visual cue that this module is keeping up with too much state. See
    //       note above about eventually cleaning this up)
    reset: (state) => {
        state.availability = null;
        state.facility = null;
        state.inmate = null;
        state.inmates = null;
        state.date = null;
        state.intervals = null;
        state.time = null;
        state.voucher = null;
        state.isVoucherConfirmed = false;
        state.additionalVisitors = [];
        state.visit = null;
        state.additionalInvites = [];
        state.isFreeForSubscription = false;
        state.visitLocation = null;
    },
    updateAvailability: (state, availability) => {
        state.availability = availability;
    },
    updatePricePerInterval: (state, fee) => {
        state.availability.price_per_interval = fee;
    },
    updatePricePerIntervalForVisit: (state, fee) => {
        state.visit.price_per_interval = fee;
    },
    updatePerVisitorCostForVisit: (state, fee) => {
        state.visit.per_visitor_cost = fee;
    },
    updateFacility: (state, facility) => {
        state.facility = facility;
    },
    updateInmate: (state, inmate) => {
        state.inmate = inmate;
    },
    updateInmates: (state, inmates) => {
        state.inmates = inmates;
    },
    updateDate: (state, date) => {
        state.date = date;
    },
    updateIntervals: (state, intervals) => {
        state.intervals = intervals;
    },
    updateTime: (state, time) => {
        state.time = time;
    },
    updateVoucher: (state, voucher) => {
        state.voucher = voucher;
    },
    updateIsVoucherConfirmed: (state, value) => {
        state.isVoucherConfirmed = value;
    },
    updateIsFreeForSubscription: (state, value) => {
        state.isFreeForSubscription = value;
    },
    updateVisit(state, visit) {
        state.visit = visit;
    },
    updateVisitLocation(state, location) {
        state.visitLocation = location;
    },
    /** Additional Visitors */
    updateAdditionalVisitors: (state, visitors) => {
        state.additionalVisitors = visitors;
    },
    pushAdditionalVisitor(state, visitor) {
        state.additionalVisitors.push(visitor);
    },
    removeAdditionalVisitor(state, index) {
        state.additionalVisitors.splice(index, 1);
    },
    /** Additional Invites (those added from my-account) */
    updateAdditionalInvites: (state, invites) => {
        state.additionalInvites = invites;
    },
    pushAdditionalInvite(state, invite) {
        state.additionalInvites.push(invite);
    },
    removeAdditionalInvite(state, index) {
        state.additionalInvites.splice(index, 1);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
