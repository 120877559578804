<template>
    <ul @click="toggleNavClosed">
        <li>
            <router-link v-if="isMobile" class="mb-2" to="/">
                <img
                    src="@assets/images/nav-logo.png"
                    alt="iWebVisit.com Logo"
                    class="nav-logo"
                />
            </router-link>
            <img
                v-else
                src="@assets/images/nav-logo.png"
                alt="iWebVisit.com Logo"
                class="nav-logo"
            />
        </li>
        <li class="nav-spacer">
            <router-link :to="{ name: 'about-us', hash: '#anchor' }">
                About Us
            </router-link>
        </li>
        <li class="nav-spacer">
            <router-link :to="{ name: 'visitor-info', hash: '#anchor' }">
                Visitor Info
            </router-link>
        </li>
        <li class="nav-spacer">
            <router-link :to="{ name: 'confidential', hash: '#anchor' }">
                Confidential
            </router-link>
        </li>
        <li>
            <router-link :to="{ name: 'support', hash: '#anchor' }">
                Support
            </router-link>
        </li>
        <!--
        <li>
            <a :href="salesUrl">Sales</a>
        </li>
        -->
        <template v-if="isMobile">
            <li v-if="isMyAccountShowing">
                <router-link :to="{ name: 'my-account', hash: '#anchor' }">
                    My Account
                </router-link>
            </li>
            <li v-if="isScheduleNowButtonShowing">
                <router-link
                    class="sidebar-emphasis"
                    :to="{ name: 'my-account', hash: '#schedule' }"
                    @click="scrollToElement('schedule')"
                >
                    Schedule Now!
                </router-link>
            </li>
            <!-- TODO(Push login/log out to bottom of container) -->
            <li v-if="isAuthenticated">
                <a href="#" @click.prevent="logout()"> Log Out </a>
            </li>
            <li v-if="isLoginShowing">
                <router-link
                    class="sidebar-emphasis"
                    :to="{ name: 'auth.login', hash: '#top-anchor' }"
                >
                    Login
                </router-link>
            </li>
        </template>
        <template v-else>
            <li v-if="isScheduleNowButtonShowing" class="ml-auto mr-1">
                <router-link
                    class="btn btn-lg btn-lg-rounded schedule-now"
                    :to="{ name: 'my-account', hash: '#schedule' }"
                    @click="scrollToElement('schedule')"
                >
                    Schedule Now!
                </router-link>
            </li>
        </template>
    </ul>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { routing } from '@/mixins/routing';
import Vue from 'vue';

export default {
    mixins: [routing],
    props: ['isMobile'],
    data() {
        return {
            salesUrl: process.env.VUE_APP_SALES_URL,
        };
    },
    computed: {
        ...mapGetters('Auth', ['isAuthenticated']),

        isMyAccountShowing() {
            return this.isAuthenticated && this.$route.name !== 'my-account';
        },
        isLoginShowing() {
            return !this.isAuthenticated && this.$route.name !== 'auth.login';
        },
        isScheduleNowButtonShowing() {
            return this.isAuthenticated && !this.isScheduling;
        },
    },
    methods: {
        ...mapMutations('Nav', ['toggleNavClosed']),
        ...mapActions('Auth', ['logoutRequest']),
        ...mapActions('User', ['removeUser']),

        logout() {
            this.logoutRequest().then(() => {
                Vue.swal('You have been successfully logged out.');

                if (this.$route.name !== 'index') {
                    this.$router
                        .push({ name: 'index' })
                        .then(() => this.removeUser());
                } else {
                    this.removeUser();
                }
            });
        },
    },
};
</script>
