import moment from 'moment-timezone';

export const COUNTDOWN_MINUTES = 15; // how many minutes before visit start that we begin counting down
export const ADDITIONAL_VISITORS_LIMIT = 7;
export const VOUCHER_LENGTH = 10;
export const SERVER_ERROR_MESSAGE =
    'Oops! There seems to be a server error. Please try again later.';
export const BREAKPOINT_SMALL = 576;
export const BREAKPOINT_MEDIUM = 768;

// TODO(Moment is deprecated. Luxon should eventually replace)
export class Helper {
    static dateFromUTC(visit) {
        let start = visit.scheduled_for_utc * 1000;
        let tz = visit.facility.timezone.olsen;
        return {
            value: moment(start).tz(tz).format('Y-MM-DD'),
            label: moment(start).tz(tz).format('dddd MMMM D, YYYY'),
            is_free: visit.is_ffh,
        };
    }

    static timesFromUTC(starts, ends, ts, tz) {
        let format = 'h:mma';

        return {
            from: moment(starts).tz(tz).format(format),
            to: moment(ends).tz(tz).format(format),
            timestamp: ts,
        };
    }

    static isUserPaying(visitor) {
        return visitor.payment === 'user' || visitor.payment === 'subscriber';
    }
}
