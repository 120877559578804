<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import api from '@/lib/api';

export default {
    created() {
        console.log('Loaded app');
        api.csrf();
    },
};
</script>

<style lang="scss">
@import './styles/globals';
</style>
